/* ReportPage.scss */

/* Center text in table cells */
.text-center .ant-table-cell {
  text-align: center;
}

/* Spinner container to center the loading spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Header styling */
.header-page-questions {
  display: block;
  font-size: 24px;
  font-weight: bold;

}


/* Button hover effects */
.ant-btn-primary:hover {
  background-color: #2c3e50;
  border-color: #2c3e50;
}

.ant-btn-default:hover {
  border-color: #3D5A80;
  color: #3D5A80;
}

/* Adjust table scroll container */
.table-scroll-container {
  overflow-x: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .header-page-questions {
    font-size: 20px;
  }

  .ant-btn {
    padding: 6px 12px;
    font-size: 14px;
  }

  .ant-table {
    font-size: 12px;
  }
}

/* Optional custom scrollbars */
.table-scroll-container::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.table-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Ensure the table has enough width */


/* Cursor styles for table scroll */
.table-scroll-container {
  cursor: grab; /* default “hand” cursor */
  -webkit-overflow-scrolling: touch; /* For iOS smooth scrolling */
}
.table-scroll-container:active {
  cursor: grabbing; /* when user is clicking down/dragging */
}
.toggle-button.active {
  background-color: #F37761 !important;
  color: white;
}
.setupcontainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.setUp {
  border: none;
  background: none;
  padding: 10px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease;
}
.Shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.BTM-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.setUp:hover {
  opacity: 0.9;
}
.setUp {
  background-color: #F37761 !important;
  color: white;
}
.Reports {
    flex-direction: column;
  align-items: center;
}
.system-main-container {
  overflow-y: auto;
  display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 100%;
  // min-height: calc(100vh - 173px) !important;
  padding: 0 15px; /* Add padding to prevent overflow */
}
.Btns-Section {
  display: flex;
  gap: 1rem;
}
// Ensure the cards fit within the section
.div1, .div2, .div3 {
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  padding: 0 15px; /* Add padding to prevent overflow */
}


.revenue-card {
  width: 100%;


}


.custom-tooltip {
  background-color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tooltip-value {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  margin: 0;
}


.tooltip-value {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  margin: 0;
}

.recharts-cartesian-grid-horizontal line {
  stroke-opacity: 0.2;
}

.recharts-tooltip-wrapper {
  outline: none;
}

.recharts-xaxis .recharts-cartesian-axis-tick-value {
  font-size: 16px;
}

.recharts-yaxis .recharts-cartesian-axis-tick-value {
  font-size: 16px;
}