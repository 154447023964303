@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import '@fortawesome/fontawesome-free/css/all.css';

html,
body {
  scroll-behavior: smooth;
  background-color: $primary-background;
  font-family: "Poppins", sans-serif;
  color: $primary-color;
  --sb-track-color: #98c1d9;
  --sb-thumb-color: #3d5a80;
  --sb-size: 8px;
}
#root > div > div > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.ant-steps > div.ant-steps-item.ant-steps-item-finish > div > div.ant-steps-item-icon{
  background-color: $text-white !important;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

#root > div > div > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.register.style-steps > div:nth-child(2) > div > div.flag-dropdown.open > ul > li.search {
   display: flex;
  justify-content: center;
  align-items: center;
}
#root > div > div > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.register.style-steps > div:nth-child(2) > div > div.flag-dropdown.open > ul > li {
  display: flex;
gap: 1rem;
  align-items: center;
}



#root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(2) > div > div > div > div > div > div > table > tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 {
  padding: 0;
  // display: inline;
  // background: #EE6C4D;
}
#root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(2) > div > div > div > div > div > div > table > tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
  padding: 0;
  padding-left: 3.10rem;
  border: none;
}

#root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(2) > div > div > div > div > div > div > table > tbody > tr > td > div > div > div > div > div > div > div > table > tbody > tr > td.ant-table-cell {
  // background-color: brown;
  border-top: 1px;
}
#root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(2) > div > div > div > div > div > div > table > tbody > tr > td > div > div > div > div > div > div > div > table > tbody > tr > td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  width: 103px !important;
}
#root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(2) > div > div > div > div > div > div > table > tbody > tr:nth-child(2) > td > div > div > div > div > div > div > div > table {
  border : 1px;
}

// #root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(3) > div > div > div > div > div > div > table > tbody > tr:nth-child(2) > td {
//   padding: 0;

//   //  padding-left: 7rem;
 
//   background: #3D5A80;
// }



// #root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(3) > div > div > div > div > div > div > table > tbody > tr:nth-child(2) > td > div > div > div > div > div > div > div > table > tbody > tr > td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
//   // width: 130px !important;
//   padding: 16px;
//   background-color: #EE6C4D;
// }
// #root > div > div > div > div > main > div > div > div > div > div.table-scroll-container > div:nth-child(3) > div > div > div > div > div > div > table > tbody > tr:nth-child(2) > td > div > div > div > div > div > div > div > table > tbody > tr > td.ant-table-cell {
// width: 78px !important;
//   padding: 16px;
//   background-color: blue;
// }


:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
  color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
  color: $primary-color;
  height: 100%;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-icon .ant-steps-icon{
  line-height: unset;
}
#root > div > div > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.ant-steps > div.ant-steps-item.ant-steps-item-wait > div > div.ant-steps-item-icon span{
 color: #FFFFFF !important;
}


#root > div > div > main > div > div > div > div > div > div > div.ant-steps-item-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div > div > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item > div > div.ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div > div > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item > div > div.ant-steps-item-icon span {
 font-size: large;
 font-weight: 700;
}

#root > div > div > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item-wait > div > div.ant-steps-item-icon{
  background-color: #FFFFFF !important;
}

#root > div > div > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item-wait > div > div.ant-steps-item-icon span{
  font-size: large;
  font-weight: 700;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 1px;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  // text-transform: capitalize !important;
}


:where(.css-dev-only-do-not-override-p8b6i).ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  background-color: $primary-color;
  color: $text-white;
  border-radius: 16px;
  border: 2px solid $primary-color !important;
}



.ant-message {
  border-radius: 2px solid $secondary-color !important;
  top: 18% !important;
  left: 50% !important; 
  transform: translate(50%, -50%); 
}

.free-p span{
  color: #3d5a80 !important;
}


.ant-message {
  .ant-message-notice {
    .ant-message-custom-content span{
      // background-color: #3d5a80;
      color: #3d5a80 !important;
    }
  }
}

:where(.css-p8b6i).ant-message .ant-message-notice-wrapper .ant-message-notice-content{
  background-color: #3d5a80;

}

:where(.css-p8b6i).ant-select .ant-select-selection-item{
  color: #3d5a80 !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}


:where(.css-p8b6i).ant-modal .ant-modal-content{
  height: 80vh;
  width: 1000px;
  right: 50%;
  margin: 0; 
  position: relative;
  bottom: 30px;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId{
  color: $primary-color;
}

:where(.css-p8b6i).ant-layout.ant-layout-has-sider{
  height: 100vh;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-layout{
  height: 100vh;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-dev-only-do-not-override-ni1kz0).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  color: $primary-color;
}

:where(.css-ni1kz0).ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-ni1kz0).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  color: $primary-color !important;
}

.country span{
  color: $primary-color !important;
}

.anticon svg{
  border-color: #98c1d9 !important;
}



.my-app-layout {
  height: 100vh;  /* absolutely lock to viewport */
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

#root > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item.ant-steps-item-wait > div > div.ant-steps-item-icon {
  background-color: $text-white !important;
  border-color: $text-white !important;
  width: 60px;
  height: 32px;
  border-radius: 9px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 45px;
    height: 24px;
    border-radius: 6px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 20px;
    font-size: 12px;
  }
}


:where(.css-dev-only-do-not-override-ni1kz0).ant-steps {
  background: linear-gradient(-270deg, rgba(152,193,217,1) 0%, rgba(224,251,252,1) 100%) !important;
  border-radius: 12px !important;
  padding: 12px !important;

  /* Example of making the container responsive */
  @media (max-width: 768px) {
    padding: 8px !important;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
  color: $primary-color;
}

#root > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item.ant-steps-item-process.ant-steps-item-active > div > div.ant-steps-item-icon  {
  background-color: #3D5A80 !important;
  border-color: #3D5A80 !important;
  width: 60px ;
  height: 32px;
  border-radius: 9px;
  color: #FFFFFF !important;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: bold !important;

  .ant-steps-icon{
    span{
      color: $text-white;
    }
  }
/* Responsive adjustments */
@media (max-width: 768px) {
  width: 45px;
  height: 24px;
  border-radius: 6px;
  font-size: 14px;
}

@media (max-width: 480px) {
  width: 40px;
  height: 20px;
  font-size: 12px;
}
}

// :where(.css-ni1kz0).ant-steps .ant-steps-item-icon .ant-steps-icon{
//     background-color: #3D5A80 !important;
//     border-color: #3D5A80 !important;
//     width: 60px ;
//     height: 32px;
//     border-radius: 9px;
//     color: #FFFFFF !important;
//     cursor: pointer;
//     font-size: 16px !important;
//     font-weight: bold !important;

//     .ant-steps-icon{
//       span{
//         color: $text-white;
//       }
//     }
//   /* Responsive adjustments */
//   @media (max-width: 768px) {
//     width: 45px;
//     height: 24px;
//     border-radius: 6px;
//     font-size: 14px;
//   }

//   @media (max-width: 480px) {
//     width: 40px;
//     height: 20px;
//     font-size: 12px;
//   }
// }


:where(.css-ni1kz0).ant-steps .ant-steps-item-process .ant-steps-item-icon{
 width: 60px ;
  background-color: #3D5A80 !important;
    border-color: #3D5A80 !important;
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-icon{
  background-color: #fff !important;
  border-color: #fff !important;
  width: 60px ;
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
  background-color:$primary-color;
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon{
  color: $primary-color;
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
  color: rgba($primary-color, 0.5);
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
  color: $primary-color;
}

#root > div > div > main > div > div > div > div.ant-steps > div.ant-steps-item.ant-steps-item-process > div > div.ant-steps-item-icon  {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  width: 60px;
  height: 32px;
  border-radius: 9px;
  color: $text-white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 45px;
    height: 24px;
    border-radius: 6px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 20px;
    font-size: 12px;
  }
}


// :where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-process .ant-steps-item-icon {
//   background-color: $primary-color !important;
//   border-color: $primary-color !important;
//   width: 60px;
//   height: 32px;
//   border-radius: 9px;
//   color: $text-white;
//   cursor: pointer;
//   font-size: 16px;
//   font-weight: bold;

//   /* Responsive adjustments */
//   @media (max-width: 768px) {
//     width: 45px;
//     height: 24px;
//     border-radius: 6px;
//     font-size: 14px;
//   }

//   @media (max-width: 480px) {
//     width: 40px;
//     height: 20px;
//     font-size: 12px;
//   }
// }

// :where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-wait .ant-steps-item-icon {
//   background-color: $text-white !important;
//   border-color: $text-white !important;
//   width: 60px;
//   height: 32px;
//   border-radius: 9px;
//   cursor: pointer;
//   font-size: 16px;
//   font-weight: bold;

//   /* Responsive adjustments */
//   @media (max-width: 768px) {
//     width: 45px;
//     height: 24px;
//     border-radius: 6px;
//     font-size: 14px;
//   }

//   @media (max-width: 480px) {
//     width: 40px;
//     height: 20px;
//     font-size: 12px;
//   }
// }

// :where(.css-ni1kz0).ant-steps .ant-steps-item-wait .ant-steps-item-icon{
//   background-color: $text-white !important;
//   border-color: $text-white !important;
//   width: 60px;
//   height: 32px;
//   border-radius: 9px;
//   cursor: pointer;
//   font-size: 16px;
//   font-weight: bold;

//   /* Responsive adjustments */
//   @media (max-width: 768px) {
//     width: 45px;
//     height: 24px;
//     border-radius: 6px;
//     font-size: 14px;
//   }

//   @media (max-width: 480px) {
//     width: 40px;
//     height: 20px;
//     font-size: 12px;
//   }
// }

:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: $primary-color !important;
}

:where(.css-ni1kz0).ant-steps {
  background: linear-gradient(-270deg, rgba(152,193,217,1) 0%, rgba(224,251,252,1) 100%) !important;
  border-radius: 12px;
  padding: 12px;

  /* Example of making the container responsive */
  @media (max-width: 768px) {
    padding: 8px;
  }
}

.ant-steps-item-title{
  color: $primary-color;

}
:where(.css-ni1kz0).ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed{
  width: 50%;
}
:where(.css-ni1kz0).ant-layout-sider{
  background-color: unset;
}

#rc-tabs-1-panel-replace > div > button {
  span{
    color: #EE6C4D !important;
    font-size: 18px !important;

  }
  border-radius: 12px !important;
  width: 150px !important;
  height: 45px !important;
  padding: 12px;
  background-color: rgba($accent-color, 0.07) !important;
  border: unset;
}

#rc-tabs-1-panel-grow > div > button {
  span{
    color: #EE6C4D !important;
    font-size: 18px !important;

  }
  border-radius: 12px !important;
  width: 150px !important;
  height: 45px !important;
  padding: 12px;
  background-color: rgba($accent-color, 0.07) !important;
  border: unset;
}

#rc-tabs-1-panel-adjust > div > button {
  span{
    color: #EE6C4D !important;
    font-size: 18px !important;

  }
  border-radius: 12px !important;
  width: 150px !important;
  height: 45px !important;
  padding: 12px;
  background-color: rgba($accent-color, 0.07) !important;
  border: unset;
}

// :where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-outlined, :where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-dashed{
//   span{
//     color: #EE6C4D !important;
//     font-size: 18px !important;

//   }
//   border-radius: 12px !important;
//   width: 150px !important;
//   height: 45px !important;
//   padding: 12px;
//   background-color: rgba($accent-color, 0.07) !important;
//   border: unset;
// }


#root > div > div > div > div > main > div > div > div > div > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > button {
  span{
    color: #EE6C4D !important;
    font-size: 18px !important;

  }
  border-radius: 12px !important;
  width: 150px !important;
  height: 45px !important;
  padding: 12px;
  // background-color: rgba($accent-color, 0.07) !important;
  border: unset;
}

#root > div > div > div > div > main > div > div > div > div > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > button {

    span{
      color: #EE6C4D !important;
      font-size: 18px !important;
  
    }
    border-radius: 12px !important;
    width: 150px !important;
    height: 45px !important;
    padding: 12px;
    background-color: rgba($accent-color, 0.07) !important;
    border: unset;
  
}
#root > div > div > div > div > main > div > div > div > div > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > button.active {
  background-color: #F37761 !important;
  color: white;
}


:where(.css-ni1kz0).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{

  color: $accent-color !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: $accent-color !important;
}

:where(.css-ni1kz0).ant-tabs .ant-tabs-tab{
  color: $accent-color !important;

}


:where(.css-ni1kz0).ant-tabs .ant-tabs-ink-bar{
    background-color: $accent-color;
}

#root > div > div > main > div > div > div > div.d-flex.flex-column > div.col-12 > div > div > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div > div > div.ant-tabs.ant-tabs-top.css-dev-only-do-not-override-ni1kz0 > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div
{
  color: rgba($accent-color, 0.5) !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab{
  // color: rgba($accent-color, 0.5);
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-btn:active, :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-remove:active{
  // color: $accent-color !important;
}

#root > div > div > main > div > div > div > div.d-flex.flex-column > div.col-12 > div > div > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div > div > div.ant-tabs.ant-tabs-top.css-dev-only-do-not-override-ni1kz0 > div.ant-tabs-nav::before {
  // border-bottom: 1px solid rgba($accent-color, 0.2) !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs-top >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs-bottom >div>.ant-tabs-nav::before{
  // border-bottom: 1px solid rgba($accent-color, 0.2);
}

:where(.css-ni1kz0).ant-tabs-top >.ant-tabs-nav::before, :where(.css-ni1kz0).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-ni1kz0).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-ni1kz0).ant-tabs-bottom >div>.ant-tabs-nav::before{
  // border-bottom: 1px solid rgba($accent-color, 0.2);
}

:where(.css-ni1kz0).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-btn:active, :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-remove:active{
  // color: rgba($accent-color, 0.5);
}

#root > div > div > main > div > div > div > div.d-flex.flex-column > div.col-12 > div > div > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div > div > div.ant-tabs.ant-tabs-top.css-dev-only-do-not-override-ni1kz0 > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > .ant-tabs-tab-remove:active {
  // color: $accent-color !important;
}

:where(.css-ni1kz0).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-btn:active, :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-remove:active {
  // color: $accent-color !important;
}

:where(.css-ni1kz0).ant-tabs .ant-tabs-tab-btn:hover, :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-remove:hover {
  // color: rgba($accent-color, 0.5) !important;
}


:where(.css-ni1kz0).ant-tabs .ant-tabs-tab-btn:focus-visible, :where(.css-ni1kz0).ant-tabs .ant-tabs-tab-remove:focus-visible{
  // outline: 3px solid  rgba($accent-color, 0.5);
  border-radius: 12px;
  padding:5px;
}
:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-btn:focus-visible, :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-remove:focus-visible{
  // outline: 3px solid  rgba($accent-color, 0.5);
  border-radius: 12px;
  padding:5px;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  // color: $accent-color;
    text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-ink-bar{
  // background:$accent-color
}


:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-icon{
  background-color: #FFF !important;
    border-color: #3d5a80 !important;
    span{
      color: #3d5a80 !important;
    }
    // width: 60px;
}
:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
 
  color: #EE6C4D !important;
 
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-icon .ant-steps-icon{
  line-height: unset;
}

:where(.css-ni1kz0).ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon{
  color: $primary-color !important;
}

.recharts-text{
  span{
    fill: $primary-color !important;
  }
  fill: $primary-color !important;
  color: #3D5A80 !important;
  tspan{
    fill: $primary-color !important;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-thead >tr>td{
  background-color: rgba($accent-color, 0.8);
  color: #fff;
  border: unset !important;
}

:where(.css-ni1kz0).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-ni1kz0).ant-table-wrapper .ant-table-thead >tr>td{
//  background-color: rgba($accent-color, 0.8) !important;
  color: #fff !important;
  border: unset !important;
}

:where(.css-dev-only-do-not-override-ni1kz0)[class^="ant-table"] [class^="ant-table"], 
:where(.css-dev-only-do-not-override-ni1kz0)[class*=" ant-table"] [class^="ant-table"], 
:where(.css-dev-only-do-not-override-ni1kz0)[class^="ant-table"] [class*=" ant-table"], 
:where(.css-dev-only-do-not-override-ni1kz0)[class*=" ant-table"] [class*=" ant-table"] {
  // background-color: rgba($accent-color, 0.05);
  color: $primary-color;
  border-right: 0px solid #f0f0f0;
   font-weight: 600;

  &:hover {
    // background-color: lighten($accent-color, 10%);
  }
}

:where(.css-dev-only-do-not-override-ni1kz0)[class^=ant-table] [class^=ant-table]:hover, :where(.css-dev-only-do-not-override-ni1kz0)[class*=" ant-table"] [class^=ant-table]:hover, :where(.css-dev-only-do-not-override-ni1kz0)[class^=ant-table] [class*=" ant-table"]:hover, :where(.css-dev-only-do-not-override-ni1kz0)[class*=" ant-table"] [class*=" ant-table"]:hover{
  // background-color: lighten($accent-color, 10%) !important;
}

:where(.css-dev-only-do-not-override-ni1kz0)[class^=ant-table] [class^=ant-table], :where(.css-dev-only-do-not-override-ni1kz0)[class*=" ant-table"] [class^=ant-table], :where(.css-dev-only-do-not-override-ni1kz0)[class^=ant-table] [class*=" ant-table"], :where(.css-dev-only-do-not-override-ni1kz0)[class*=" ant-table"] [class*=" ant-table"]{
  border-right:unset ;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: $primary-color;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-ink-bar{
  background-color: #3D5A80;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-btn:focus-visible, :where(.css-dev-only-do-not-override-ni1kz0).ant-tabs .ant-tabs-tab-remove:focus-visible{
  outline: unset;
}

#root>div>div>div>div>div>div>div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center>form>div.ant-steps>div.ant-steps-item.ant-steps-item-wait>div>div.ant-steps-item-icon span{
  color:#3D5A80 !important;
}

:where(.css-ni1kz0).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-ni1kz0).ant-table-wrapper .ant-table-thead >tr>td{
  background-color: #3D5A80 !important;
}

.social-icons a {
  text-decoration: none; /* Removes underline from the links */
}

.social-icons i {
  width: 50px;
  height: 50px;
  color: var(--background-color);
  background-color: var(--text-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 0 10px;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-btn-color-dangerous.ant-btn-variant-outlined, :where(.css-dev-only-do-not-override-ni1kz0).ant-btn-color-dangerous.ant-btn-variant-dashed{
  span{
    color: #EE6C4D !important;
    font-size: 18px !important;

  }
  border-radius: 12px !important;
  width: 150px !important;
  height: 45px !important;
  padding: 12px;
  background-color: rgba($accent-color, 0.07) !important;
  border: unset;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-drawer .ant-drawer-close{
  color: #fff;
}

textarea:where(.css-dev-only-do-not-override-ni1kz0).ant-input{
  height: 130px !important;
  border: unset !important; 

}

textarea:where(.css-ni1kz0).ant-input{
  height: 130px !important;
  border: unset !important; 
}
:where(.css-dev-only-do-not-override-ni1kz0).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 48px;
}