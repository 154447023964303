@import "../variables";

.feature-page-style {
    height: 100vh;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}

.feature-page-style h3{
    font-size:  map_get($fontSize, l-med);
    font-weight: bold;
    color: $primary-color;
    margin-top: 50px;
}

.card-design{
    background-color: $primary-color;
    color: $text-white;
    width: 400px;
    height: 180px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center; // Center align items
    text-align: center;
    .icont-height{
        height: 120px; // Increase icon size
        width: 120px;
    }
    
    .hz-line{
        width: 100%; // Make the line full width
        height: 2px;
        background-color: #f8f9fa39;
        position: relative;
        margin: 10px 0;
    }

    span{
        font-weight: bold;
        font-size:  map_get($fontSize, x-med);
    }

    p{
        font-weight: 400;
        color: $secondary-color;
        font-size:  map_get($fontSize, small);
    }

    .card-logo{
        width: 120px; // Increase icon size
        height: 120px;
    }
}

@media (max-width: 576px) {
    .feature-page-style {
        height: auto;
        padding: 20px;
    }
    
    .feature-page-style h3{
        margin-top: 20px;
        color: $primary-color;
        font-size:  map_get($fontSize, l-med);
        text-align: center;
    }
    
    .card-design{
        width: 100%;
        height: auto;
        border-radius: 16px;
        margin: 10px auto; 
        display: flex;
        flex-direction: column;
        align-items: center; // Center align items
        text-align: center;
        padding: 15px;
        .icont-height{
            height: 120px; // Increase icon size
            margin-bottom: 10px;
            width: 120px;
        }
        
        .hz-line{
            width: 100%; // Make the line full width
            height: 2px;
            background-color: $primary-background;
            position: relative;
            margin: 10px 0;
        }
    
        span{
            font-weight: bold;
            color: $primary-background;
            font-size:  map_get($fontSize, med);
        }
    
        p{
            font-weight: 400;
            color: $primary-background;
            font-size:  map_get($fontSize, small);
            margin-top: 10px;
        }
    
        .card-logo{
            width: 120px; // Increase icon size
            height: 120px;
        }
    }
        
    .cards-between{
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) {
    .feature-page-style {
        height: auto;
    }
    
    .feature-page-style h3{
        color: $primary-color;
        font-size:  map_get($fontSize, x-large);
    }
    
    .card-design{
        width: 90%;
        height: auto;
        border-radius: 32px;
        margin: 5px auto; 
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
        .icont-height{
            height: 120px; // Increase icon size
            margin-bottom: 10px;
            width: 120px;
        }
        
        .hz-line{
            width: 100%; // Make the line full width
            height: 2px;
            background-color: $primary-background;
            position: relative;
        }
    
        span{
            font-weight: bold;
            color: $primary-background;
            font-size:  map_get($fontSize, x-med);
        }
    
        p{
            font-weight: 400;
            color: $primary-background;
            font-size:  map_get($fontSize, small);
        }
    
        .card-logo{
            width: 120px; // Increase icon size
            height: 120px;
        }
    }
        
    .cards-between{
        margin-bottom: -50px;
    }
}

@media (min-width: 768px) {
    .feature-page-style {
        height: auto;
    }
    
    .feature-page-style h3{
        margin-top: 30px;
        color: $primary-color;
        font-size:  map_get($fontSize, large);
    }
    
    .card-design{
        width: 80%;
        height: auto;
        border-radius: 32px;
        margin: 5px auto; 
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
        .icont-height{
            height: 120px; // Increase icon size
            margin-bottom: 10px;
            width: 120px;
        }
        
        .hz-line{
            width: 100%; // Make the line full width
            height: 2px;
            background-color: $primary-background;
            position: relative;
        }
    
        span{
            font-weight: bold;
            color: $primary-background;
            font-size:  map_get($fontSize, x-med);
        }
    
        p{
            font-weight: 400;
            color: $primary-background;
            font-size:  map_get($fontSize, small);
        }
    
        .card-logo{
            width: 120px; // Increase icon size
            height: 120px;
        }
    }
        
    .cards-between{
        margin-bottom: -50px;
    }
}

@media (min-width: 992px) {
    .feature-page-style {
        height: auto;
        padding: 40px 0;
    }
    
    .feature-page-style h3{
        margin-top: 30px;
        color: $primary-color;
        font-size:  map_get($fontSize, large);
        text-align: center;
    }
    
    .card-design{
        width: 30%;
        max-width: 450px;
        height: auto;
        border-radius: 16px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
        .icont-height{
            height: 120px; // Increase icon size
            margin-bottom: 10px;
            width: 120px;
        }
        
        .hz-line{
            width: 100%; // Make the line full width
            height: 2px;
            background-color: $primary-background;
            position: relative;
        }
    
        span{
            font-weight: bold;
            color: $primary-background;
            font-size:  map_get($fontSize, l-small);
            text-align: left;
        }
    
        p{
            font-weight: 400;
            color: $primary-background;
            font-size:  map_get($fontSize, x-small);
            text-align: left;
        }
    
        .card-logo{
            text-align: center;
            width: 120px; // Increase icon size
            height: 120px;
            margin-bottom: 15px;
        }
    }
        
    .container.pb-5{
        padding-bottom: 50px;
    }
        
    .cards-between{
        margin-bottom: -30px;
    }
}

@media (min-width: 1200px) {
    .feature-page-style {
        height: auto;
        padding: 40px 0;
    }
    
    .feature-page-style h3{
        margin-top: 30px;
        color: $primary-color;
        font-size:  map_get($fontSize, large);
        text-align: center;
    }
    
    .card-design{
        width: 30%;
        max-width: 450px;
        height: auto;
        border-radius: 16px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
        .icont-height{
            height: 120px; // Increase icon size
            margin-bottom: 10px;
            width: 120px;
        }
        
        .hz-line{
            width: 100%; // Make the line full width
            height: 2px;
            background-color: $primary-background;
            position: relative;
        }
    
        span{
            font-weight: bold;
            color: $primary-background;
            font-size:  map_get($fontSize, l-small);
            text-align: left;
        }
    
        p{
            font-weight: 400;
            color: $primary-background;
            font-size:  map_get($fontSize, x-small);
            text-align: left;
        }
    
        .card-logo{
            width: 120px; // Increase icon size
            height: 120px;
            margin-bottom: 15px;
        }
    }
        
    .container.pb-5{
        padding-bottom: 50px;
    }
        
    .cards-between{
        margin-bottom: -30px;
    }
}



