@import "../variables";

.footer-container {
  background-color: #F8F8FF;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}

.footer {
  background: linear-gradient(to right, #b8d5e6, #c5dced);
  padding: clamp(1rem, 2vw, 1.5rem);
  color: white;
  width: 70%;
  min-width: 300px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.footer-content {
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(300px, 2fr) repeat(2, minmax(150px, 1fr));
  gap: clamp(1rem, 2vw, 2rem);
  justify-items: center;
  align-items: start;
}

/* Company Info Section */
.footer-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 200px;
}

.footer-logo {
  width: 120px;
  height: 50px;
  position: relative;
  margin-bottom: 0.5rem;
}

.footer-logo img {
  width: 100%;
  height: auto;
}

.footer-address {
  margin-top: 0.5rem;
  font-size: clamp(0.75rem, 1.2vw, 0.85rem);
  line-height: 1.4;
  white-space: nowrap;

}

/* Footer Sections (Support & Company) */
.footer-section {
  text-align: center;
  width: 100%;
  max-width: 200px;
}

.footer-heading {
  color: #1e64b4;
  margin-bottom: 0.5rem;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
}

.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  transition: color 0.2s;
  font-size: clamp(0.75rem, 1.2vw, 0.85rem);
  padding: 0.2rem 0;
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  margin-top: clamp(1rem, 2vw, 1.5rem);
  font-size: clamp(0.7rem, 1.2vw, 0.8rem);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 0.75rem;
}

/* Responsive adjustments */
@media (max-width: 820px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
 gap: 1rem;
  }

  .footer-info,
  .footer-section {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
 
  }

  .footer-address {
   text-align: center;
  
  }
  

  .footer-info,
  .footer-section {
    max-width: 100%;
  }
}

// .footer {
//   width: 70%;
//   max-width: 70%;
//   z-index: 1000 !important;
//   padding: 1rem;
//   background-color: $secondary-color !important;
//   height: 100%;
//   position: relative;
//   border-radius: 32px;
//   box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
  
//   h5 {
//     font-size: 18px;
//     color: #0c64bc;
//     font-weight: bold;
//   }
// }

// .p-footer {
//   color: $text-white;
//   font-size: 16px;
// }


// @media (max-width: 1199px) and (min-width: 992px) {
//   .footer {
//     width: 80%; 
//     border-radius: 28px; 
//   }

//   h5 {
//     font-size: 16px; // Decrease the font size for smaller screens.
//   }

//   .p-footer {
//     font-size: 14px;
//   }
//   .footer-logo{
//     width: 80%;
//     height: auto;
//   }
// }

// @media (max-width: 991px) and (min-width: 768px) {
//   .footer {
//     width: 90%; 
//     padding: 10px; 
//     border-radius: 20px; 
//   }

//   h5 {
//     font-size: 12px; // Smaller font size for headings.
//   }

//   .p-footer {
//     font-size: 10px; 
//   }

//   .footer-address {
//     text-align: center; 
//   }
//   .footer-logo{
//     width: 80%;
//     height: auto;
//   }
// }

// @media (max-width: 767px) {
//   .footer {
//     width: 100%; 
//     padding: 6px; 
//     border-radius: 10px;
//   }

//   h5 {
//     font-size: 10px; 
//   }

//   // span {
//   //   font-size: 8px; 
//   // }

//   .p-footer {
//     font-size: 9px; 
//   }

//   .footer-address {
//     text-align: center; 
//     margin: 1px 1px 1px 1px; 
//   }

//   .footer-logo {
//     width: 70%; 
//     height: auto; 
//   }
// }

