@import "../variables";
.layout-content {
  height: calc(100vh - 64px); // Adjusted to account for the fixed header
}
@media (max-width: 820px) {
    .cardstep {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
    }
  }

.financial-header{
    h2{
        font-size: 32px;
        font-weight: bold ;
        color: $primary-color;
    }

    p{
        padding-right: 10px;
        font-size: 16px;
    }
}

.setupStyle{
    background-color: $secondary-color;
    color: $primary-color;
    padding:  10px;
    border-radius: 22px;
    text-align: center;
    height: 100%;
    width: 100%;
    min-width: 90px;
}

.card-style-finicalsetup{
    background-color: $primary-color;
    border-radius: 22px;
    color: $text-white;
    padding:  10px 20px 10px 20px;

    h4{
        font-weight: bold;
        font-size: 22px;
    }

    .btn-setup-finincal{
        background-color: $text-white;
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase !important;
        width: 100%;
        min-width: 80px;
        border-radius: 12px;
        margin-bottom: 13px;
    }

    .btn-setup-finincal:hover{
        background-color: $primary-color;
        color: $text-white;
    }
    .btn-setup-finincalgreen{
        background-color: #9ADE7B;
        color: $text-white;
        font-weight: bold;
        text-transform: uppercase !important;
        width: 100%;
        min-width: 80px;
        border-radius: 12px;
        margin-bottom: 13px;
    }

    .btn-setup-finincalgreen:hover{
        background-color: #7CBF5E;
        color: $text-white;
    }
    .btn-setup-finincalred{
        background-color: #FF6868;
        color: $text-white;
        font-weight: bold;
        text-transform: uppercase !important;
        width: 100%;
        min-width: 80px;
        border-radius: 12px;
        margin-bottom: 13px;
    }

    .btn-setup-finincalred:hover{
        background-color: #CC5555;
        color: $text-white;
    }
    .btn-setup-finincalyellow{
        background-color: #F7D060;
        color: $text-white;
        font-weight: bold;
        text-transform: uppercase !important;
        width: 100%;
        min-width: 80px;
        border-radius: 12px;
        margin-bottom: 13px;
    }

    .btn-setup-finincalyellow:hover{
        background-color: darken(#F7D060, 10%);
        color: $text-white;
    }
}



.scrollable-buttons-container-setup {
    height: 65vh;  
    overflow-x: hidden;  
    overflow-y: auto;
    padding-right: 10px;
    -ms-overflow-style: none;  
}

@media (max-width: 768px) {
    .scrollable-buttons-container-setup {
        height: 40vh;  
        padding-right: 5px;
    }
}

.scrollable-buttons-container-setup::-webkit-scrollbar {
    width: 0; 
}

.scrollable-buttons-container-setup::-webkit-scrollbar {
    width: 6px; 
    overflow-y: hidden;
}

.scrollable-buttons-container-setup::-webkit-scrollbar-thumb {
    background-color: $secondary-color; 
    border-radius: 4px; 
    overflow-y: hidden;
}

.arrow-logo{
    position: relative;
    top: 88px;
    right: 15px;
    width: 100px;
    height: 150px;
}

.arrow-logo-responsive{
    width: 100px;
  height: 100px;
}

.btn-finish-setup{
    border-radius: 12px !important;
    background-color: $accent-color;
    border: 2px solid $accent-color;
    padding: 10px;
    color: $text-white;
    font-size: 22px;
}


.btn-finish-setup:hover{
    background-color: transparent !important;;
    color: $accent-color !important;
    border: 2px solid $accent-color !important;
}

.disabled-setup {
    opacity: 0.6;
    pointer-events: none;
  }
  
  .btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .QuestionsDiv {
    transition: all 0.1s ease-in-out;
  }
  .ai-suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    height: 20rem;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px; /* Visible and narrow scrollbar */
      overflow-y: hidden;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color; /* Use your theme's color */
      border-radius: 4px;
      overflow-y: hidden;
    }
  }
  
  .ai-suggestion-item {
    font-size: 12px;
    font-weight: 600;
    color: #555;
color: $primary-color;
font-weight: 500;
word-wrap: break-word; /* Ensures long words or URLs break and wrap to the next line */
  white-space: normal; /* Allows the text to wrap naturally */
  word-break: break-word;
    transition: transform 0.2s ease-in-out;
    display: flex;
text-align: left !important;
    flex-direction: column;
  }
  .ai-suggestion-item span {
    text-align: left !important;
  }


  .ai-suggestion-card {
    // background-color: $secondary-color;
    border: 2px solid $primary-color;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba($primary-color, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
    color: $primary-color;
    transition: all 0.2s ease-in-out;
   height: auto;

  }
  
  .ai-suggestion-icon {
    background-color: $primary-color;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  .ai-suggestion-title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  
  .ai-suggestion-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
  }
  
  #root > div > div > div > div > main > div > div > div > div.MainSection > div.ant-steps{
    // overflow-x: auto;
    width: 100%
  }
  .MainSection {
    gap: 0.2rem;
    // flex-wrap: wrap;
  }
  .ai-button {
    background-color: #Ffff;
    border: 2px solid #3D5A80;
  border-radius: 12px;
  font-size: 18px;
  font-weight: bold;
  padding: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .ai-button:hover {
    background-color: $primary-color;

  }
  .headerBTM-page {
    font-size: 22px;
    font-weight: bold;
    color: $primary-color;
   

    text-align: center;
    padding: 2px;

 
 }
 .ai-button:hover .icon {
    color: $secondary-color;
  }
  .icon {
    width: 2rem;
    height: 2rem;
    color: $primary-color;
  }
  

  .header-page {
    font-size: 22px;
    font-weight: bold;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 32px;

    text-align: center;
    padding: 5px;
  
    @media (max-width: 768px) {
        font-size: 18px;
        width: 50%;
        padding: 10px;
    }
  
    @media (max-width: 480px) {
        font-size: 16px;
        width: 80%;
        padding: 15px;
    }
  }
  
.header-page-questions{
    font-size: 22px;
    font-weight: bold;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 32px;
    width: 30%;
    margin-bottom: 32px;
    text-align: center;
    padding: 5px;

    @media (max-width: 768px) {
        font-size: 18px;
        width: 50%;
        padding: 10px;
    }

    @media (max-width: 480px) {
        font-size: 16px;
        width: 80%;
        padding: 15px;
    }
}

