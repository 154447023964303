@import "../variables";

.login-section {
  position: relative;
  top: 100px;
  height: calc(100vh - 116px);
  background: linear-gradient(-270deg, rgba(152,193,217,1) 0%, rgba(224,251,252,1) 100%);
  border-radius: 32px 32px 0 0;
  display: flex;
  align-items: center;
  overflow: hidden !important; 
  span {
    font-size:  map_get($fontSize, l-small);
}

.span-route{
    color: $primary-color;
    font-weight: 600;
    font-size:  map_get($fontSize, l-small);
}
  // @media (max-width: 1000px) {

  //   overflow: auto !important; 
  // }

}

.login-container {
  width: 100%;
}

.Login-design {
  @media (max-width: 1000px) and (min-width: 765px) {

    // margin-top: 45rem; 
  }
  .login-form-wrapper {
    width: 100%;
    max-width: 400px;
  }
  h2 {
    text-align: center;
    font-size: 21px;
    font-weight: bold;
  }
}

.login-logo {
  width: 80%;
  max-width: 500px;
}

.form-desin-login input {
  background-color: $primary-background !important;
  border: 2px solid $primary-color;
  border-radius: 8px;
  color: $primary-color !important;
}

.form-label {
  font-size: map_get($fontSize, l-small);
  color: $primary-color;
}

.form-control:focus {
  background-color: $primary-background !important;
}

.or-section {
  .line1 {
    height: 1.5px;
    background-color: $primary-color;
  }
  .or-text {
    font-size: map_get($fontSize, small);
    color: $primary-color;
    text-align: center;
  }
}

span {
  font-size: map_get($fontSize, small);
  color: $text-white;
  .span-route {
    color: $primary-color;
    font-weight: 600;
    margin-left: 8px !important;
  
  }
}

.btn-submit-login {
  height: 48px;
  border-radius: 8px !important;
  background-color: $primary-color;
  color: $primary-background !important;
  font-weight: bold;
  border: 2px solid $primary-background !important;
  transition: 0.3s;
  &:hover {
    background-color: transparent !important;
    border: 2px solid $primary-color !important;
    color: $primary-color !important;
  }
}

@media (max-width: 576px) {
  .login-logo {
    display: none;
  }
  .Login-design {
    .login-form-wrapper {
      max-width: 100%;
    }
    h2 {
      text-align: center;
      font-size: map_get($fontSize, x-med);
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .login-logo {
    display: none;
  }
  .Login-design {
    .login-form-wrapper {
      max-width: 100%;
    }
    h2 {
      text-align: center;
      font-size: map_get($fontSize, x-med);
    }
  }
}

@media (min-width: 768px) {
  .login-logo {
    display: block;
  }
  .Login-design {
    h2 {
      text-align: center;
      font-size: map_get($fontSize, x-med);
    }
  }
}

@media (min-width: 992px) {
  .login-logo {
    display: block;
  }
  .Login-design {
    h2 {
      text-align: center;
      font-size:21px;
    }
  }
}

@media (min-width: 1200px) {
  .login-logo {
    display: block;
  }
  .Login-design {
    h2 {
      text-align: center;
      font-size: 21px;
    }
  }
}
