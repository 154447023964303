@import "../variables";
.main-card {
  box-shadow: 0px 3px 10px 0px #f5f5f5;
  margin-top: 2px;
  display: flex !important;
 flex-direction: column !important;
 width: 100%;
 height: 100%;
}

.card-container {
 display: flex !important;
 flex-direction: column !important;
 gap: 2px;
 width: 100%;
 height: 100%;
}
.card-questioner {
  margin-top: 20px;
  background-color: #FFFFFF;
display: flex;
flex-direction: column;
  border-radius: 32px;
  height: 50vh;
  padding: 10px;
  width: 100%;
  // overflow-y: auto;
  max-height: 50vh;

  // response-area {
  //     max-height: 30%;
  // }
  span {
    justify-content: start !important;
  }

  @media (max-width: 1200px) {
    height: 50vh;
    max-height: 70vh;
  }

  @media (max-width: 992px) {
    height: 50vh;
    max-height: 80vh;
  }

  @media (max-width: 768px) {
    height: 50vh;
    max-height: 70vh;
    
  }

  @media (max-width: 576px) {
    height: 50vh;
    max-height: 100vh;
  }

  .span-input {
    background-color: rgba($accent-color,0.07) !important;
    padding: 10px !important;
    color: $accent-color!important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 44px !important;
    width: auto !important;
    text-align: center !important;
    border-radius: 12px !important;
    margin-right: 5px;
  }


  span {
    color: $primary-color;
    font-size: 28px;
    font-weight: bold;
    line-height: 2rem;
  }

  h1 {
    text-align: start;
    font-size: 32px;
  }

  p {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    color: $secondary-color;
  }

  .btn-choice {
    border: 2px solid $accent-color;
    width: 100%;
    color: $accent-color;
    padding: 10px;
    border-radius: 32px;
    height: 50px;
    min-width: 50px;
    padding: 0 100px;
  }

  .btn-choice:hover {
    background-color: $accent-color;
    color: #ffffff;
  }

  .btn-choice.selected {
    border: 3px solid $accent-color;
    color: #fff !important;
    background-color: $accent-color;
  }

  .response-area {
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    min-width: 100%;
    // padding-right: 10px;
    // padding: 5px;
    -ms-overflow-style: none; /* IE and Edge */

 &::-webkit-scrollbar {
    width: 6px; /* Visible and narrow scrollbar */
    overflow-y: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color; /* Use your theme's color */
    border-radius: 4px;
    overflow-y: hidden;
  }
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{    background-color: rgba($accent-color,0.09);
    background-color: rgba($accent-color,0.20) !important;
  }
  .react-datepicker {
    z-index: 9999 !important;
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative; // Changed from static to relative
    line-height: initial;
  }


  .date-input{
    z-index: 9999999999999999999999999;
    background-color: rgba($accent-color,0.07) !important;
    padding: 10px !important;
    color: $accent-color!important;
    font-size: 18px !important;
    height: 44px !important;
    width: auto !important;
    text-align: center !important;
    border-radius: 12px !important;
    margin-right: 5px;
    border: unset !important;
  }
  .react-datepicker__header{
    background-color: rgba($accent-color,0.07) !important;
    padding: 10px !important;
    color: $accent-color!important;
    width: auto !important;
    text-align: center !important;
    border: unset !important;
  }

  .react-datepicker__week{
    background-color: rgba($accent-color,0.09) !important;
    padding: 10px !important;
    color: $accent-color!important;
    width: auto !important;
    text-align: center !important;
    border: unset !important;
  }

  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color:$accent-color;
  }

  .react-datepicker__current-month{
    color: $accent-color!important;

  }
  
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    color: $accent-color!important;
  }

  .react-datepicker__day-name{
    color: $accent-color!important;
  }

  // /* Hide scrollbar for Chrome, Safari, and Edge */
  // .response-area::-webkit-scrollbar {
  //   width: 0;
  // }

  // /* Hide scrollbar for Firefox */
  // .response-area {
  //   scrollbar-width: none; /* Firefox */
  // }

  // /* Disable scrollbar dragging for all browsers */
  // .response-area {
  //   -ms-overflow-style: none;
  // }

  .Q6 {
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    // gap: 20px;
    // padding-left: -50%;
    width: 100%;

    .label-option {
      width: 20%;
      font-size: 18px;
      font-weight: 700;
      color: #3d5a80;
      justify-content: flex-start;
    }

    .response-dropdown {
      // min-width: 30%;
      // width: 40%;
      display: flex;
      background-color: #ee6c4d12;
      padding: 12px 28px 12px 24px;
      border: none;
      outline: none;
      border-radius: 12px;
      font-size: 18px;
      font-weight: 700;
      color: #ee6c4d80;
      justify-content: flex-start;
      //   appearance: none;
      //   -webkit-appearance: none;
      //   -moz-appearance: none;
    }

    @media (max-width: 500px) {
      .response-dropdown {
        width: 100%;
        padding: 10px 20px;
        font-size: 12px;
      }
    }
  }

  .Q7-input {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .Q7 {
      // width: 33%;
      display: flex;
      flex-direction: column;
      position: relative;

      .label-option {
        font-size: 18px;
        font-weight: 700;
        color: #3d5a80;
        justify-content: flex-start;
      }

      .input-response {
        color: #ee6c4d80;
        font-size: 18px;
        font-weight: 700;
        align-items: center;
        display: flex;
        background-color: #ee6c4d12;
        border-radius: 12px;
        border: none;
        outline: none;
        // padding: 12px 24px 12px 76px;
        padding: 5px;
        width: 15%;

        &::placeholder {
          color: #ee6c4d80;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        @media (max-width: 500px) {
          .response-dropdown {
            width: 100%;
            padding: 10px 20px;
            font-size: 12px;
          }
        }
      }

      @media (max-width: 500px) {
        .input-response {
          min-width: 30%;
        }
      }

      .input-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        /* Ensure the dollar icon stays within the wrapper */
      }

      .dollar-icon {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ee6c4d;
        color: #ffffff;
        padding: 12px 14px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 700;
        margin-right: 8px;
        /* Adjust the spacing as needed */
      }

      @media (max-width: 530px) {
        .dollar-icon {
          top: 72%;
          width: 20px;
          padding: 10px 20px;
          font-size: 12px;
        }
      }
    }
  }

  .Q8-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .Q8 {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .label-option {
        font-size: 18px;
        font-weight: 700;
        color: #3d5a80;
        justify-content: flex-start;
      }

      .input-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
      }

      .label-responses {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        .input-wrapper {
          display: flex;
          align-items: center;
          gap: 20px;
          width: 25%;
        }

        .Q8input-response {
          display: flex;
          align-items: center;
          color: #ee6c4d80;
          font-size: 18px;
          font-weight: 700;
          background-color: #ee6c4d12;
          border-radius: 12px;
          border: none;
          outline: none;
          padding: 12px 24px 12px 76px;
          //  width: 100%;
          min-width: 200px;
          box-sizing: border-box;

          &::placeholder {
            color: #ee6c4d80;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .Q8-input-response {
          color: #ee6c4d80;
          font-size: 18px;
          font-weight: 700;
          background-color: #ee6c4d12;
          border-radius: 12px;
          border: none;
          outline: none;
          padding: 12px 24px;
          width: 100%;
          box-sizing: border-box;
        }

        .react-datepicker-wrapper .Q8-input-response {
          z-index: 9999 !important;
          background-color: #ee6c4d12 !important;
          color: #ee6c4d80 !important;
          border: none !important;
          font-size: 18px !important;
          font-weight: 700 !important;
          border-radius: 12px !important;
          outline: none !important;
          padding: 12px 24px !important;
          width: 100% !important;
          min-width: 200px !important;
        }

        .Q8-input-response::placeholder {
          color: #ee6c4d80;
          opacity: 1;
        }

        .react-datepicker__input-container {
          width: 100%;
        }

        .react-datepicker__input-container input {
          color: #ee6c4d80;
          font-size: 18px;
          font-weight: 700;
          background-color: transparent;
          padding: 12px 24px;
          border: none;
          outline: none;
        }

        // .Q8-dollar-icon {
        //   width: 55px;
        //   height: 30px;
        //   position: absolute;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   left: 13px;
        //   top: 65%;
        //   transform: translateY(-50%);
        //   background-color: #ee6c4d;
        //   color: #ffffff;
        //   padding: 12px 14px;
        //   border-radius: 12px;
        //   font-size: 18px;
        //   font-weight: 700;
        // }

        .Q8-dollar-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #3d5a80;
          /* Dark blue background */
          color: white;
          /* White text */
          padding: 0.5rem 1rem;
          /* Padding for a visually balanced button */
          border-radius: 0.375rem 0 0 0.375rem;
          /* Rounded left corners */
        }

        .Q8input-response {
          width: 8rem;
          /* 32px in rem units */
          padding: 0.5rem 0.75rem;
          /* Padding for text box */
          border: 1px solid #ccc;
          /* Light gray border */
          border-radius: 0 0.375rem 0.375rem 0;
          /* Rounded right corners */
          outline: none;
          /* Remove default focus outline */
          transition: all 0.3s ease;
          /* Smooth focus effect */
        }

        .Q8input-response:focus {
          border-color: transparent;
          box-shadow: 0 0 0 2px #3b82f6;
          /* Blue ring on focus */
        }

        @media (max-width: 530px) {
          .Q8-dollar-icon {
            top: 70%;
            width: 20px;
            padding: 10px 20px;
            font-size: 12px;
          }
        }

        .perMonth {
          background-color: #ee6c4d12;
          color: #ee6c4d80;
          padding: 12px 24px;
          border-radius: 12px;
          font-size: 18px;
          font-weight: 700;
          width: 25%;
          min-width: 200px;
          text-align: start;
        }

        .from {
          font-size: 7px;
          font-weight: 700;
          color: #ee6c4d80;
          text-align: start;
          white-space: nowrap;
        }
      }
    }
  }

  .custom-radio-buttons .btn-check:checked+.btn {
    font-size: 16px;
    font-weight: 700;
    background-color: #ee6c4d;
    color: white;
  }

  .custom-radio-buttons .btn-check:not(:checked)+.btn {
    font-size: 16px;
    font-weight: 700;
    background-color: #ee6c4d12;
    color: #ee6c4d80;
  }

  @media (max-width: 400px) {
    .response-dropdown {
      width: 100%;
      padding: 10px 20px;
      font-size: 8px;
    }
  }
}

.btn-previous-questioner,
.btn-next-questioner,
.btn-done-questioner {
  background-color: #f5f5f5;
  width: 49.5%;
  border-radius: 12px;
  padding: 5px;
  // margin: 10px;
  // position: relative;
  // top: 280px;
}

.btn-next-questioner.full-width {
  width: 100% !important;
}

.btn-previous-questioner {
  border: 2px solid $primary-color;
  background-color: #fff;
  color: $primary-color;
  font-size: 28px;
  font-weight: bold;
}

.btn-previous-questioner:hover {
  background-color: $primary-color;
  color: #fff;
}

.btn-next-questioner {
  background-color: #f5f5f5 !important;
  font-size: 28px;
  font-weight: bold;
  color: $primary-color;
}

.btn-next-questioner:hover {
  background-color: $primary-color !important;
  color: #f5f5f5;
}

.btn-done-questioner {
  background-color: $primary-color !important;
  font-size: 28px;
  font-weight: bold;
  color: #f5f5f5;
}

.style-extraInfoLabel {
  label {
    font-size: 18px;
    font-weight: 400;
    color: $accent-color;
  }
}

.btn-regenerate {
  background-color: $secondary-color;
  border-radius: 32px;
  color: #fff;
  padding: 10px;
  font-weight: bold;
}

.btn-regenerate:hover {
  background-color: $primary-color;
  color: #fff;
}

@media (max-width: 1399px) {
  .card-questioner {
    padding: 20px;
    max-width: 100%;
    min-width: 250px;
    max-height: 50vh;

    .btn-choice {
      height: 50px;
      padding: 10px 5px;
    }

    span {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    .btn-choice {
      border: 2px solid $accent-color;
      width: 100%;
      padding: 10px;
      border-radius: 32px;
      height: auto;
      min-width: 50px;
    }
  }

  .btn-previous-questioner,
  .btn-next-questioner,
  .btn-done-questioner {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .card-questioner {
    padding: 20px;
    max-width: 100%;
    min-width: 250px;
    max-height: 50vh;
    overflow-y: scroll;

    .btn-choice {
      height: 50px;
      padding: 10px 5px;
    }

    span {
      font-size: 22px;
    }

    p {
      font-size: 12px;
    }

    .btn-choice {
      border: 2px solid $accent-color;
      width: 100%;
      padding: 10px;
      border-radius: 32px;
      height: auto;
      min-width: 50px;
    }
  }

  .btn-previous-questioner,
  .btn-next-questioner,
  .btn-done-questioner {
    font-size: 24px;
  }
}

@media (max-width: 530px) {
  .card-questioner {
    padding: 20px;
    min-width: 200px;
    overflow-y: scroll;

    .btn-choice {
      padding: 8px 8px;
      font-size: 14px;
    }

    span {
      font-size: 20px;
    }

    p {
      font-size: 12px;
    }
  }

  .btn-previous-questioner,
  .btn-next-questioner,
  .btn-done-questioner {
    font-size: 14px;
  }
}

input {
  // border-radius: 22px;
  padding: 8px;
  margin-right: 5px;
  border: 2px solid $primary-color;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: $accent-color;
    outline: none;
  }
}

// .form-select{

//   border-radius: 12px;
//   padding: 8px;
//   margin-right: 5px;
//   border: 2px solid $primary-color;
//   transition: border-color 0.3s ease;

//   &:focus {
//     border-color: $secondary-color;
//     outline: none;
//     box-shadow : 0 0 0 0.25rem #98c1d955;
//   }
// }

.form-select {
  padding: 0.5rem 1rem;
  padding-right: 3rem;
  /* Equivalent to px-3 py-2 */
  border: 1px solid #ccc;
  /* Light gray border */
  border-radius: 0.375rem;
  /* Rounded corners */
  background-color: white;
  /* Ensure a white background */
  outline: none;
  /* Remove default focus outline */
  font-size: 1rem;
  /* Adjust font size */
  line-height: 1.5;
  /* Adjust line height for better spacing */
  transition: all 0.3s ease;
  /* Smooth focus effect */
  appearance: none;
  /* Remove default browser styling (optional) */

  &:focus {
    border-color: transparent;
    /* Remove border color */
    box-shadow: 0 0 0 2px #3b82f6;
    /* Add a blue focus ring */
    outline: none;
  }
}

.button-wrapper {
  position: relative;

  .bgclose {
    background: $secondary-color;
  }

  .bgedit {
    background: $primary-color;
  }

  .edit-button {
    position: absolute;
    right: 0%;
    top: 0%;
    transform: translateY(-50%);
    border: none;
    padding: 0.3rem;
    font-size: 12px;
    border-radius: 50%;

    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;

    .cancelBTn {
      color: $primary-color;
    }

    .EditBTn {
      color: $secondary-color;
    }


  }
}

.button-wrapper:hover .edit-button {
  opacity: 1;
}

.edit-input {
  width: 100%;
  padding: 9px 19px;
  font-size: 16px;
  border: 2px solid #3498db;
  border-radius: 5px;
  outline: none;
}

.edit-input:focus {
  border-color: #2980b9;
}

// -------------------------

.select-wrapper {
  position: relative;
  width: 100%;
  
}

.custom-select {
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 700;
  background: rgba(238, 108, 77, 0.07);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #EE6C4D80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease;

  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

}

.custom-select:hover {
  box-shadow: 0 2px 8px rgba(238, 108, 77, 0.2);
}

.custom-select .select-text {
  flex-grow: 1;
  font-size: 18px !important;
  color: #EE6C4D80;
}

.select-arrow {
  transition: transform 0.3s ease;
}

.select-arrow.open {
  transform: rotate(180deg);
}

.select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #FFE8E8;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(238, 108, 77, 0.15);
  z-index: 10;
  overflow: hidden;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.select-option {
  padding: 12px 16px;
 font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 14px !important;
  color: #EE6C4D80;
}

.select-option:hover {
  background-color: rgba(238, 108, 77, 0.1);
}

.select-option:not(:last-child) {
  border-bottom: 1px solid rgba(238, 108, 77, 0.1);
}

.period-content {
  width: 20%;
  margin-right: 1rem;
}

.toggle-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #FFE8E8;
  border-radius: 8px;
  padding: 4px;
  width: fit-content;
}

.toggle-button {
  border: none;
  background: none;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease;
}

.toggle-button:hover {
  opacity: 0.9;
}

.toggle-button.active {
  background-color: #F37761 !important;
  color: white;
}

@media (max-width: 768px) {
  .toggle-button {
    padding: 10px 16px;
    font-size: 14px; /* Smaller text size for smaller screens */
  }
  .toggle-container {
    background-color: #000;
   margin: auto;
  }
}
@media (max-width: 594px) {
  .toggle-button {
    padding: 10px 16px;
    font-size: 14px; /* Smaller text size for smaller screens */
  
  }

  .toggle-container {
    background-color: #000;
   margin: auto;
  }

}
@media (max-width: 480px) {
  .toggle-button {
    font-size: 12px; /* Even smaller text size for tiny screens */
    padding: 8px 12px;
    
  }
  .toggle-container {
    background-color: #000;
   margin: auto;
  }
}

.select-wrapper2 {
  position: relative;
  // width: 100%;
}

// .period-content2 {
//   width: 10%;
//   margin-right: 1rem;
// }

.select-arrow2 {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select2 {
  width: 20%;
  padding: 16px;
  padding-right: 40px; /* Space for the arrow */
  font-size: 16px;
  background-color: #FFE8E8;
  border: none;
  border-radius: 8px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  color: #EE6C4D80;
}


.custom-select2:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(238, 108, 77, 0.1);
}

/* Remove default arrow in IE */
.custom-select2::-ms-expand {
  display: none !important;
}

/* Style for placeholder option */
.custom-select2 option[value=""][disabled] {
  color: #EE6C4D80;
}

/* Style for other options */
.custom-select2 option {
  color: #EE6C4D;
  background-color: white;
}


// ------------------------------------




.input-div {
  position: relative; // Add relative positioning to the container

  span {
    background-color: $accent-color !important;
    padding: 10px !important;
    color: $text-white !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 30px !important;
    width: 50px !important;
    text-align: center !important;
    border-radius: 22px !important;
    position: absolute !important; // Position the span absolutely within the container
    top: 50% !important; // Center vertically
    left: 10px !important; // Adjust the left position as needed
    transform: translateY(-50%) !important; // Center vertically
  }

  input {
    width: 181px !important;
    height: 45px !important;
    border-radius: 12px !important;
    background-color: rgba($accent-color, 0.07) !important;
    color: rgba($accent-color, 0.5) !important;
    font-weight: bold !important;
    font-size: 18px !important;
    border: unset !important;
    padding-left: 70px !important; // Add padding to the left to make space for the span

    &::placeholder {
      color: $accent-color !important; // Set placeholder color to accent color
    }
  }
}

.Q8-dollar-icon-sub{
  background-color: $accent-color !important;
  padding: 10px !important;
  color: $text-white !important;
  font-size: 18px !important;
  font-weight: bold !important;
  height: auto !important;
  width: auto !important;
  text-align: center !important;
  border-radius: 22px !important;
}


.date-input{
  background-color: rgba($accent-color,0.07) !important;
  padding: 10px !important;
  color: $accent-color!important;
  font-size: 18px !important;
  width: auto !important;
  text-align: center !important;
  border-radius: 12px !important;
  border: unset !important;
}

.Q8-dollar-icon{
  background-color: $accent-color !important;
  padding: 10px !important;
  color: $text-white !important;
  font-size: 18px !important;
  font-weight: bold !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: auto !important;
  width: auto !important;
  text-align: center !important;
  border-radius: 22px !important;
}

.input-div-varible {
  position: relative; // Add relative positioning to the containers
  .lable-input-varible {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $accent-color !important;
    padding: 10px !important;
    color: $text-white !important;
    font-size: 15px !important;
    font-weight: bold !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 30px !important;
    width: 150px !important;
    text-align: center !important;
    border-radius: 22px !important;
    position: absolute !important; // Position the span absolutely within the container
    top: 50% !important; // Center vertically
    left: 10px !important; // Adjust the left position as needed
    transform: translateY(-50%) !important; // Center vertically
  }

  input {
    width: 300px !important;
    height: 45px !important;
    border-radius: 12px !important;
    background-color: rgba($accent-color, 0.07) !important;
    color: rgba($accent-color, 0.5) !important;
    font-weight: bold !important;
    font-size: 18px !important;
    border: unset !important;
    padding-left: 200px !important; // Add padding to the left to make space for the span

    &::placeholder {
      color: $accent-color !important; // Set placeholder color to accent color
    }
  }

  
  
  
}

#rc-tabs-15-panel-grow > div > button {
  background-color: rgba($accent-color, 0.07) !important;
  color: #EE6C4D !important;
  border: unset !important;
}
// .btn-varible{
//   background-color: rgba($accent-color, 0.07) !important;
//   color: #EE6C4D;
//   border: unset;
// }

.sticky-button{
  position: sticky;
  bottom: 0;
  z-index: 999;
}

.sticky-steps{
  position: sticky;
  top: 0;
  z-index: 999;
}

.toggle-switch {
  position: relative;
  display: flex;
  // justify-content: center;
  align-items: center;
  // width: 100%;
  // height: 100%;
  gap: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  background-color: rgba(243, 119, 97, 0.2);
  border-radius: 24px;
  transition: background-color 0.2s ease-in-out;
}

.toggle-knob {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Checked state */
.toggle-switch input:checked + .toggle-slider {
  background-color: #F37761;
}

.toggle-switch input:checked + .toggle-slider .toggle-knob {
  transform: translateX(24px);
}

/* Hover effects */
.toggle-switch:hover .toggle-slider {
  background-color: #D1D5DB;
}

.toggle-switch:hover input:checked + .toggle-slider {
  background-color: #E16550; /* Darker shade for hover */
}

/* Focus styles for accessibility */
.toggle-switch input:focus + .toggle-slider {
  box-shadow: 0 0 0 2px rgba(243, 119, 97, 0.2);
}

.toggle-label {
  font-size: 17px !important;
  color: #4B5563;
  font-weight: 500;
}

/* Required indicator */
.toggle-switch input[required] + .toggle-slider::after {
  content: "*";
  position: absolute;
  right: -12px;
  top: -2px;
  color: #EF4444;
  font-size: 14px;
}

/* Animation for the knob */
@keyframes toggle-pop {
  0% {
    transform: scale(1) translateX(24px);
  }
  50% {
    transform: scale(0.9) translateX(24px);
  }
  100% {
    transform: scale(1) translateX(24px);
  }
}

.toggle-switch input:checked + .toggle-slider .toggle-knob {
  animation: toggle-pop 0.2s ease-in-out;
}

.header-page-card{
  border: 2px solid #3D5A80;
  border-radius: 12px;
  font-size: 18px;
  font-weight: bold;
  padding: 6px;
  color: #3D5A80;
}