@import "../variables";

.navbar-style {
    background-color: $secondary-color !important;
    height: 90px;
    border-radius: 32px;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    padding: 45px;
    position: fixed;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 1000;
}

.navbar-style a {
    color: $text-white;
    font-size: 16px;
}

.navbar-style a:hover {
    color: $rar-gradient;
}

.btn-login-header {
    width: 124.57px;
    height: 40px;
    border: 2px solid $primary-background !important;
    border-radius: 32px !important;
    color: $text-white !important;
    margin-right: 15px;
    transition: 0.4s;
}

.btn-login-header:hover {
    background-color: $primary-background !important;
    color: $secondary-color !important;
}

.btn-tryNow {
    width: 124.57px;
    background-color: $primary-background !important;
    height: 40px;
    border-radius: 32px !important;
    color: $secondary-color !important;
    border: 2px solid $primary-background !important;
    margin-right: 15px;
    transition: 0.4s;
}

.btn-tryNow:hover {
    background-color: transparent !important;
    border: 2px solid $primary-background !important;
    color: $text-white !important;
}

.modern-toggler {
    border: none;
    background-color: transparent;
    outline: none;
}

.modern-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 576px) {
    .navbar-style {
        padding: 10px;
        border-radius: 16px;
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    }

    .navbar-style a {
        font-size: 18px; // Increased font size
        color: $primary-background;
    }

    .btn-login-header, .btn-tryNow {
        width: 100%;
        height: 40px;
        font-size: 18px; // Increased font size
        border-radius: 20px;
        margin: 5px 0;
    }

    .navbar-collapse {
        background-color: $secondary-color;
        border-radius: 16px;
        padding: 20px;
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    }

    .navbar-nav {
        flex-direction: column;
        align-items: center;
    }

    .nav-item {
        margin-bottom: 10px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .navbar-style {
        padding: 30px;
    }

    .navbar-style a {
        font-size: 15px;
    }

    .btn-login-header, .btn-tryNow {
        width: 110px;
        height: 38px;
        font-size: 15px;
    }
}

@media (min-width: 768px) {
    .navbar-style {
        padding: 45px;
    }

    .navbar-style a {
        font-size: 16px;
    }

    .btn-login-header, .btn-tryNow {
        width: 124.57px;
        height: 40px;
        font-size: 16px;
    }
}
