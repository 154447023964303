@import "../variables";

.screenshot-capture {
  position: relative;
}

.bugicon {
  font-size: 32px;
  transition: all 0.3s ease;
  color: $text-white
}

.capture-btn:hover .bugicon {
  transform: rotate(15deg);
}

.capture-btn {
  position: fixed;
  bottom: 50px;
  left: 50px;
  width: 75px;
  height: 75px;
  background-color: rgba($secondary-color,0.9);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 14px 0 rgba($primary-color, 0.39);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
}

.capture-btn[type="file"] {
  display: none;
}

.capture-btn[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.capture-btn[type="file"]::before {
  content: 'Upload';
  display: inline-block;
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 50%;
  padding: 10px 20px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
  transition: all 0.3s ease;
}

.capture-btn[type="file"]:hover::before {
  background-color: $secondary-color;
  box-shadow: 0 4px 14px 0 rgba($secondary-color, 0.39);
  transform: scale(1.05);
}

.capture-btn:hover {
  background-color: $primary-color;
  box-shadow: 0 4px 14px 0 rgba($secondary-color, 0.39);
  transform: scale(1.05);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.popup-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 30px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.animate-popup {
  animation: popIn 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.popup-title {
  font-size: 24px;
  color: $primary-color;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
}

.canvas-container {
  flex-grow: 1;
  overflow: hidden;
  margin-bottom: 20px;
  border: 2px solid $primary-color;
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(24, 144, 255, 0.1);
  cursor: crosshair;
}

.canvas-container canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.description-input {
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  resize: none;
  border: 2px solid $primary-color;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
}

.animate-input {
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.description-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.animate-btn {
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.close-btn {
  background-color: #f5f5f5;
  color: #333;
}

.close-btn:hover {
  background-color: #e8e8e8;
}

.download-btn {
  background-color: #1890ff;
  color: white;
}

.download-btn:hover {
  background-color: #40a9ff;
}

.submit-btn {
  background-color: $primary-color;
  color: white;
}

.submit-btn:hover {
  background-color: $secondary-color;
}

.submit-btn:disabled {
  background-color: #d9d9d9;
  color: #ffffff;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .popup-card {
    padding: 20px;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
    margin: 5px 0;
  }
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: $primary-color;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
.form-title .note {
  font-size: 12px;
  color: $secondary-color;
}

.draw-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.draw-icon {
  font-size: 2rem;
  color: rgba(255, 0, 0, 0.7);
}

.draw-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.draw-icon {
  font-size: 2rem;
  color: rgba(255, 0, 0, 0.7);
}
