$primary-background:#FFFFFF;

$text-white:#FFFFFF;
$primary-color : #3D5A80;
$secondary-color: #98C1D9;
$accent-color: #EE6C4D;

$dark-text:#293241;
$rar-gradient: rgba(224, 251, 252, 1);

$fontSize:(
    x-small:12px,
    small:14px,
    l-small:16px,
    x-med:18px,
    med:22px,
    l-med:32px,
    x-large:40px,
    large:60px,
    l-large:64px
)
