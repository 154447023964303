.slider-container {
  display: flex;
  justify-content: center; /* Center the slider horizontally */
  background: #F8F8FF;

}

.logo-slider {
  overflow: hidden;
  width: 100%;
  max-width: 1295px; /* Example max-width for the slider */
  background: #F8F8FF;
  padding: 30px 0;
}

h7 {
  text-align: center !important;
  font-size: 22px !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #3D5A80 !important;
}

.slider-track {
  display: flex;
  animation: scroll 20s linear infinite;
}

.logo {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.logo img {
  width: 150px;
  height: 200px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * var(--scroll-distance)));
  }
}

@media (max-width: 768px) {
  .logo img {
    width: 70px;
    height: 70px;
  }
  .logo {
    margin: 0 10px;
  }
}
