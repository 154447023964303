@import "../variables";


.privacySection{
    overflow: hidden;
    height: auto;
    position: relative;
    top: 111px;
    padding: 20px;
    border-radius: 32px 32px 0 0;

    h1{
        font-size:  map_get($fontSize, large);
        font-weight: bold;
        color: $primary-color;
    }

    .btn-terms{
        width: 150px;
        height: 60px;
        border-radius: 32px;
        background-color: $primary-color;
        color: $text-white;
        font-size:  map_get($fontSize, small);
        font-weight: 800;
        transition: 0.3s;
        border: 2px solid $primary-color;
    }

    .btn-terms:hover{
        background-color: transparent; 
        border: 2px solid $primary-color;
        color: $primary-color;
    }

    .privacy-content{
        width: 100%;
    }

    h3{
        font-size:  map_get($fontSize, med);
        font-weight: 800;
        color: $primary-color;
    }

    p{    
        font-size: map_get($fontSize, small);
    }
    
}
.scroller li{
    list-style: inside !important;
    font-size: map_get($fontSize, small);
    font-weight: 400;
}



.scroller {
    position: relative;
    overflow-y: auto;
    max-height: 500px;
  }
  
  .scroller::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Optional: just to ensure the background is transparent */
  }
  
  .scroller {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .scroller::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

.div-color{
    background-color: $primary-color;
} 

@media (max-width: 576px) {
    .privacySection{
        width: 100%;
        height: auto;
        h1{
            font-size:  map_get($fontSize, med);
            text-align: center;
        }
        h3{
            font-size:  map_get($fontSize, x-med);
            text-align: left;
            
        }
        li{
            width: 100%;
            text-align: left;
            
        }
        p{
            width: 100%;
            text-align: left;
        }
        .col-10{
            padding-top: 40px;
            text-align: left;
        }
        .col-2{
           
        }
    }
    .btn.btn-terms{
        width: 150;
        height: 30px;
        font-size: 8px;
        display: block;
        margin: 0 90px 0 90px;        
        text-align: center;      
        align-items: center;
    }
    
}

@media (min-width: 576px) {
    .privacySection{
        width: 100%;
        height: auto;
        text-align: left;
        h1{
            font-size:  map_get($fontSize, l-med);
            text-align: center;
        }
        h3{
            font-size:  map_get($fontSize, x-med);
            
           
        }
        li{
            width: 100%;
           
           
        }
    }
    .btn.btn-terms{
        width: 90%;
        height: 60px;
        font-size: 6px;
    }
}

@media (min-width: 768px) {
    .privacySection{
        width: 100%;
        height: auto;
        h1{
            font-size:  map_get($fontSize, x-large);
            text-align: center;
        }
        h3{
            font-size:  map_get($fontSize, med);
           
        }
        li{
            width: 100%;
           
        }
    }
    .btn.btn-terms{
        width: 90%;
        height: 60px;
        font-size: 8px;
    }
}

@media (min-width: 992px) {
    .privacySection{
        width: 100%;
        height: auto;
        h1{
            font-size:  map_get($fontSize, x-large);
            text-align: center;
        }
        h3{
            font-size:  map_get($fontSize, med);
           
        }
        li{
            width: 100%;
           
        }
    }
    .btn.btn-terms{
        width: 90%;
        height: 60px;
        font-size: 10px;
    }
}

@media (min-width: 1200px) {
    .privacySection{
        width: 100%;
        height: auto;
        h1{
            font-size:  map_get($fontSize, x-large);
            text-align: center;
        }
        h3{
            font-size:  map_get($fontSize, med);
           
        }
        li{
            width: 100%;
           
        }
    }
    .btn.btn-terms{
        width: 90%;
        height: 60px;
        font-size: 10px;
    }
}
