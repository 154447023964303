@import "../variables";
  .containerr {
    background-color: #ffff;
    font-family: "Poppins", sans-serif;
  }
  .fixed-card {
    position: sticky; /* Makes the card stick to its container when scrolling */
    top: 0; /* Position where the sticky behavior starts */
    background-color: #fff; /* Background to overlap other content if needed */
    z-index: 10; /* Ensure it's above overlapping content */
    display: flex; /* Use Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column;
  }
  .form-container {
    max-width: 1084.55px;
    // min-height: 575px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 12px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

 
  }
  
  h1 {
    color: $primary-color;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 4px;
    // padding: 16px 24px 0;
    text-align: center;

  }
  
  .breadcrumb {
    padding: 0 24px;
    margin-bottom: 16px;
    width: 100%;
   display: flex;
   justify-content: center;
   
  }
  
  .breadcrumb-text {
    display: inline-block;
    padding: 3px 12px;
    background-color: $text-white;
    border-radius: 20px;
    color: $primary-color;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid $primary-color;
  }
  
  .tabs {
    display: flex;
   padding: 0.4rem;
    gap: 0.5rem;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    background-color: rgba(245, 245, 245, 1);
    width: fit-content;
    border-radius: 16px;
  }
  
  .tab {
    padding: 8px 20px;
    background-color: $text-white;
    border: none;
    cursor: pointer;
    color: $primary-color;
    font-size: 22px;
    font-weight: 700;
    transition: all 0.2s ease;
    border-radius: 12px;
  }
  .tab:hover {
    background-color: $secondary-color;
  }
  
 
  .tab.active {
    background-color: $primary-color;
    color: $text-white;
    font-size: 22px;
    font-weight: 700;
  }

  
  .form-content {
    // padding-left: 48px ;
    // padding-right: 48px ;
    padding-top: 20px;
    padding-bottom: 20px ;
 
    background-color: rgba(245, 245, 245, 1);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
  justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    min-height: 592px;
    overflow-y: auto;
    
  }
  
  .form-group {
   
    width: 70%;
  }
  
  .form-group label {
    display: block;
    color: #455571;
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 22px;
  }
  
  .form-input {
    width: 100%;
    padding: 6px 10px;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    color: $primary-color;
    background-color: #fff;
    transition: all 0.2s ease;
    height: 50px;
  }
  .form-content-card{
    width: 700px;
  }
  
  .form-input:focus {
    outline: none;
    border-color: $primary-color;
  }
  
  textarea.form-input {
    resize: vertical;
    min-height: 60px;
    height: auto;
    border: none !important;
  }
  

  select.form-input {
    appearance: none;
    padding-right: 28px;
    cursor: pointer;
    border: none;
  }
  
  .form-footer {
    background-color: $primary-color;
    padding: 10px 24px;
    margin-top: 20px;
  }

  .UserForm {
    display: flex;
width: 100%;
border-top-left-radius: 16px;
padding: 0 4rem;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(245, 245, 245, 1);
    min-height: 592px;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
      
width: 50%;
        gap: 0.5rem;
        span {
            color: $secondary-color;
        
        }
    }

    .UserData {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-size: 18px;
        font-weight: 700;
        background-color: #fff;
        span {
            color: $secondary-color;
            text-align: left;
        }
        h1 {
            font-size: 18px;
        }
        .part {
            flex: 1;
           
            justify-self: flex-start;
        }
    }
  }
  .btm-Container {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  .btm-Container .save-button {
    width: 30%;
    padding: 21px;
    margin: 0.3rem;
    background-color: white;
    color: $primary-color;
    border: none;
    border-radius: 6px;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
  }
  
  .btm-Container .save-button:hover {
    background-color: transparent;
    border: 2px solid #FFF;
    color: #fff;
  }
  
  @media screen and (max-width: 768px) {
    .UserForm {
        padding: 0 1rem;
      }
    
      .UserForm .title {
        width: 100%;
        font-size: 10px;
      }
    
      .UserForm .UserData {
        font-size: 10px;
      }
    
      .UserForm .UserData .part {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
      }
    .form-container {
      padding: 1rem;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .form-content {
      padding: 1rem;
    }
  
    .form-group {
      width: 90%;
    }
  
    .tabs {
      flex-wrap: wrap;
      gap: 0.25rem;
    }
  
    .tab {
      padding: 0.25rem 1rem;
      font-size: 0.875rem;
    }
  
    .breadcrumb {
      padding: 0.5rem;
    }
  
    .btm-Container .save-button {
      max-width: 100%;
      padding: 0.5rem;
      font-size: 0.875rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .UserForm {
      padding: 1.5rem 2rem;
    }
    .UserForm .title {
      font-size: 1.2rem;
    }
    .UserForm .UserData {
      font-size: 16px;
    }
    .UserForm .UserData .part {
      margin: 0.5rem;
    }
    .btm-Container .save-button {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 768px) {
   
    .btm-Container .save-button {
      font-size: 14px;
      padding: 0.6rem 1rem;
      width: 60%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .UserForm {
      padding: 0.5rem;
    }
    .UserForm .title {
      font-size: 0.9rem;
    }
    .UserForm .title span {
      font-size: 0.8rem;
    }
    .UserForm .UserData {
      font-size: 12px;
    }
    .UserForm .UserData .part {
      flex-basis: 100%;
      margin: 0.3rem 0;
    }
    .btm-Container .save-button {
      font-size: 12px;
      padding: 0.5rem;
      width: 80%;
    }
  }
  @media screen and (max-width: 820px) {
    .UserForm {
      padding: 1.2rem;
    }
    .UserForm .title {
      flex-direction: column;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
    .UserForm .title span {
      font-size: 0.9rem;
    }
    .UserForm .UserData {
      flex-direction: column;
      align-items: center;
      font-size: 15px;
    }
    .UserForm .UserData .part {
      flex-basis: 100%;
      text-align: center;
      margin: 0.6rem 0;
    }
    .btm-Container .save-button {
      font-size: 14px;
      padding: 0.7rem 1rem;
      width: 70%;
    }
    .tab {
        padding: 0.25rem 1rem;
        font-size: 0.875rem;
      }
  }
  
  @media screen and (max-width: 480px) {
    .form-container {
      border-radius: 8px;
    }
  
    h1 {
      font-size: 1.25rem;
    }
  
    .tabs {
      gap: 0.25rem;
      justify-content: flex-start;
    }
  
    .breadcrumb {
      text-align: center;
    }
  }

  .category-form{
    border-radius: 6px 0 0 6px;
  }

  .generate-button{
    border-radius: 0 6px 6px 0;
    background-color: #455571;
    color: #fff;
    height: 49px;
    border: 1px solid #455571;
    transition: 0.3s;
    font-weight: bold;
  }

  .generate-button:hover{
    background-color: unset;
    border: 1px solid #455571;
    color: #455571;
  }


//   ------------------------------ plans ------------------------

.plans-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .plan-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
  }
  
  .plan-card h3 {
    margin: 0;
    color: $primary-color;
    font-size: 18px;
  }
  
  .plan-image {
    aspect-ratio: 16 / 9;
    background: none;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .DelIcon {
    color: $primary-color;
  }
  
  .plan-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .plan-info {
    font-size: 14px;
    color: $primary-color;
  }
  
  .plan-info p {
    margin: 4px 0;
  }
  
  .delete-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: $secondary-color;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .delete-button:hover {
    background-color: $secondary-color;
  }
  
  .new-plan-card {
    background: white;
    border: 2px dashed #ccc;
    border-radius: 8px;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .new-plan-card span {
    color: #455571;
    font-size: 16px;
    font-weight: 500;
  }
  
  .new-plan-card:hover {
    border-color: $primary-color;
    background-color: $secondary-color;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: 10;
  }
  
  .modall {
    background: $text-white;
    border-radius: 8px;
    padding: 24px;
    width: 90%; /* Relative size */
    max-width: 800px;
    min-height: 200px; /* Ensures visible area */
    padding: 24px;
    color: $primary-color;

  }


  
  .modall h2 {
    margin: 0 0 24px;
    font-size: 2rem;
    font-weight: 700;
    
    color: $primary-color;
  }
  
  .modal-form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 32px;
  }
  
  .form-group2 {
    margin-bottom: 0;
  }
  
  .form-group2 label {
    display: block;
    color: $secondary-color;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .form-input2 {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid $primary-color;
    color: $primary-color;
    padding: 8px 12px;
    border-radius: 4px;
    width: 100%;
    font-size: 14px;
    appearance: none;
    outline: none;
    cursor: pointer;
  }
  
  .form-input2 option {
    background: $primary-color;
    color: white;
  }
  
  .plan-name-input {
    display: flex;
    align-items: center;
    gap: 8px;
    background: $text-white;
    border: 1px solid $primary-color;
    border-radius: 4px;
    // padding: 8px 12px;
    color: $primary-color;
  }
  
  .plan-name-input span {
    color: $text-white;
    font-size: 14px;
    background-color: $primary-color;
    padding: 8px 12px;
  }
  
  .plan-number {
    background: transparent;
    border: none;
    color: $primary-color;
    font-size: 14px;
    width: 30px;
    padding: 0;
  }
  
  .plan-number:focus {
    outline: none;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .action-buttons {
    display: flex;
    gap: 12px;
  }
  
  .button-cancel {
    background: $primary-color;
    border: 1px solid $primary-color;
    color: $text-white;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  
  .button-cancel:hover {
    background: $secondary-color;
  }
  
  .button-create {
    background: $primary-color;
    color: $text-white;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  
  .button-create:hover {
    background:  $secondary-color;
  }
  
  .button-create-start {
    background: $primary-color;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  
  .button-create-start:hover {
    background:  $secondary-color;
  }
  
  @media (max-width: 768px) {
    .modal {
      margin: 16px;
      padding: 20px;
    }
  
    .modal-form-row {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  
    .modal-buttons {
      flex-direction: column;
      gap: 16px;
    }
  
    .action-buttons {
      width: 100%;
      flex-direction: column;
    }
  
    .button-cancel,
    .button-create,
    .button-create-start,.button-delete {
      width: 100%;
    }
  }

  .alert-card {
    background: $text-white;
    border-radius: 8px;
    padding: 28px;
    color: $secondary-color;
  }
  
  .alert-card h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  
  .alert-card p {
    font-size: 14px;
    margin-bottom: 24px;
  }
  
  .alert-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .button-delete {
    background: $accent-color;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  
  .button-delete:hover {
    background: $secondary-color;
  }
  