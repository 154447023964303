@import "../variables";

  input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:focus {
  outline: none; 
  box-shadow: none; 
  background-color: transparent !important; 
}
