@import "../variables";
.Reports-container {
    max-width: 1200px;
    margin: 0 auto;
    // padding: 40px 20px;
    font-family: "Poppins", sans-serif;
   
  }
  .revenu-num {
    font-size: 18px;
    font-weight: 700;
  }
  .main-title {
    text-align: center;
    color: $primary-color;
    font-size: 22px;
    font-weight: 700;
    padding: 12px 24px;
    border: 2px solid $primary-color;
    border-radius: 22px;
    display: inline-block;
    margin: 0 auto 40px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  
  .report-card {
    background-color: #F5F7F9;
    border-radius: 12px;
    padding: 24px 40px;
    min-height: 300px;
    position: relative;
    width:100%;
  }
  
  .report-card .title {
    color: $secondary-color;
    font-size: 24px;
    margin: 0 0 12px 0;
    font-weight: 500;
    text-align: left;
    font-weight: 700;
    
  }
  
  .charts-row {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 20px;
  }
  
  .chart-box {
    background-color: white;
    border-radius: 8px;
    padding: 16px 0px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .view-text {
    color: $secondary-color;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
 
  }
  .ViewBTN {
    outline: none;
    color: $secondary-color;
    border: none;
    background-color: white;
    padding: 2px 28px;
    border-radius: 8px;
    text-align: center;
    font-weight: 700;
  }
  .ViewBTN:hover {
    color: $text-white;
    background-color: $primary-color;
    transition: all 0.5s ease;
  }

  .report-card-hover:hover .card-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(245, 247, 249, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    transition: all 0.3s ease;
  }

  .report-card-hover{
    opacity: 0.5;
  }
  .report-card-hover .card-cover span {
    display: none;
  }

  .report-card-hover:hover .card-cover{
    cursor: not-allowed;
  }
  .report-card-hover:hover .card-cover span {
    background-color: $primary-color;
    color: $text-white;
    font-size: clamp(0.9rem, 2vw, 1.2rem);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 10px 20px;
border:none;
    border-radius: 30px;
    transition: all 0.3s ease;
    
    width: 50%;
    text-align: center;
    display: inline;
  }
.LockIcon {
    box-shadow: 0 0px 1 rgba(0, 0, 0, 0.2);
}
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }

  
    .charts-row {
      flex-direction: column;
    }

      .card {
        min-height: 250px;
      }
  }


  
  
  @media (max-width: 1200px) {
    .container {
      padding: 20px;
    }
  
    .grid {
      gap: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  
    .charts-row {
      flex-direction: column;
    }
  
    .card {
      min-height: 250px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 15px;
    }
  
    .main-title {
      font-size: 20px;
      padding: 6px 18px;
    }
  
    .title {
      font-size: 20px;
    }
  

  }
  
  .ViewBTN .shiny-text {
   
    background: linear-gradient(
      120deg,
      rgba(248, 12, 12, 0) 40%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    -webkit-background-clip: text !important;
    background-clip: text;
    display: inline-block;
    animation: shine 5s linear infinite;
  }
  
  @keyframes shine {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
  
  .shiny-text.disabled {
    animation: none;
  }
  
  