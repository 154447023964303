@import "../variables";

.header-style{
    padding: 0;
    background: linear-gradient(-270deg, rgba(61,90,128,1) 0%, rgba(152,193,217,1)  100%);
    border-radius: 0 0 32px 0;
    color: $primary-color;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-layout-sider{
    background: #f5f5f5;
}

.left-slide-style{

    background-color: $primary-color;

}

    .left-slide-style span{
        font-size: 17px;
    }


.ant-layout-sider-children{
    background-color: $primary-color;
    border-radius: 0 0 50px 0;

}

:where(.css-dev-only-do-not-override-p8b6i).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-p8b6i).ant-menu-light>.ant-menu .ant-menu-item-selected{
   margin-top: 12px;
    background-color: $secondary-color;

}

.ant-menu-item-icon {
    color: $text-white !important;
    font-size: larger !important;
}

.ant-menu-item-selected .ant-menu-item-icon{
    color: $primary-color !important;
    font-size: larger !important;
}


.ant-menu-item-selected .ant-menu-title-content {
    color: $primary-color !important;
}




.btn-logout{
    width: 100px;
    background-color: $primary-color;
    font-size: 18px;
    border: 2px solid $primary-color;
    font-weight: bold;
    color: $text-white;
    transition: 0.3s;
    border-radius: 32px;
    margin-bottom: 10px;
}


.btn-logout:hover{
    background-color: transparent;
    color: $primary-color !important;
    border: 2px solid $primary-color;

}
.layout-contenttt {
    color: $primary-color;
   height: calc(100vh - 173px) !important;// Adjusted to account for the fixed header
    display: flex;
    flex-direction: column;
    scrollbar-width: none;        /* Firefox */
    -ms-overflow-style: none !important;     /* IE/Edge */
    overflow-y: auto;
  
    &::-webkit-scrollbar {
        width: 6px; /* Visible and narrow scrollbar */
        overflow-y: hidden;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: $secondary-color !important; /* Use your theme's color */
        border-radius: 4px;
        overflow-y: hidden;
      }
}
.layout-contentt{
   
    color: $primary-color;
    height: calc(100vh - 173px) !important; // Adjusted to account for the fixed header
    display: flex;
    flex-direction: column;
    scrollbar-width: none;        /* Firefox */
    -ms-overflow-style: none !important;     /* IE/Edge */
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 6px; /* Visible and narrow scrollbar */
      overflow-y: hidden;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color; /* Use your theme's color */
      border-radius: 4px;
      overflow-y: hidden;
    }
}


@media (max-width: 768px) {
    .left-slide-style span{
        font-size: 22px;
    }
    
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed{
    width: 50%;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-ni1kz0).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-ni1kz0).ant-menu-light.ant-menu-root.ant-menu-vertical, :where(.css-dev-only-do-not-override-ni1kz0).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical{
    border-inline-end:unset;
}

