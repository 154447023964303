@import "../variables";

.error-div{
     background: linear-gradient(55deg, rgba(61,90,128,1) 0%, rgba(152,193,217,1) 50%, rgba(224,251,252,1) 100%);
     height: 100vh;
}

.error-button{
    background-color: $primary-color;
    width: 200px;
    height: 51px;
    border-radius: 32px;
    color: $text-white;
    border: 2px solid $primary-color;
    transition: 0.3s;
    font-size:  map_get($fontSize, med);
    font-weight: bold;
}

.error-button:hover{
    background-color: $text-white;
    color: $primary-color;
    border: 2px solid $text-white;
}

