@import "../variables";

.support-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    height: 90vh;
    background: white;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 100;
  }
  .full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
  .content-wrapper {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  
  /* Sidebar Styles */
  .sidebar {
    width: 300px;
    background-color: $primary-color;
    color: white;
   // padding: 0px 24px 24px 24px 24px !important;
    padding:  24px !important;
    padding-top: 0;
    display: flex;
    flex-direction: column;
   
  }
  
  .sidebar h1 {
    font-size: 28px;
    font-weight: 700;
color: $text-white;
padding: 1rem;
  }
  
  .new-ticket-btn {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    font-weight: 700;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
    transition: background-color 0.2s;
  }
  
  .new-ticket-btn:hover {
    background: $secondary-color;
    color: $primary-color;
  }
  
  .tickets-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
    overflow-y: auto;
padding-right: 0.5rem;
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      width: 6px; /* Visible and narrow scrollbar */
      overflow-y: hidden;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color; /* Use your theme's color */
      border-radius: 4px;
      overflow-y: hidden;
    }
  }
  
  .ticket-item {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .ticket-item.active {
    background: $secondary-color;
    color: $text-white;
  }
  
  .ticket-meta-text {
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 8px;
  }
  
  .ticket-number {
    font-size: 24px;
    margin: 8px 0;
  }
  
  .time-info {
    font-size: 12px;
    opacity: 0.7;
  }
  
  .logo-container {
    margin-top: auto;
display: flex;
align-items: center;
justify-content: center;
    padding-top: 24px;
  }
  
  .logo {
    max-width: 150px;
    height: auto;
  }
  
  .card-footer {
    margin-top: 0;

    display: flex;
    justify-content: flex-end;
  }
  
  .close-card-button {
    background: #f3f4f6;
    color: $primary-color;
    border: none;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.2s;
    position: absolute;
    z-index: 101;
  }
  
  .close-card-button:hover {
    background: $secondary-color;
  }
  
  /* Main Content Styles */
  .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background: white;
    border-radius: 8px;
    padding: 24px;
   
    max-height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      width: 6px; /* Visible and narrow scrollbar */
      overflow-y: hidden;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color; /* Use your theme's color */
      border-radius: 4px;
      overflow-y: hidden;
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }
  
  .ticket-header {
    flex: 1;
  }
  
  .ticket-header h2 {
    font-size: 28px;
    font-weight: 700;
    color: $secondary-color;
    margin-bottom: 8px;
  }
  
  .ticket-meta {
    color: $primary-color;
    font-size: 14px;
  }
  
  .meta-bold {
    font-weight: 700;
    color: $primary-color;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: $primary-color;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .close-button:hover {
    background: $secondary-color;
  }
  
  /* Ticket Content Styles */
  .ticket-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .ticket-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .ticket-section h3 {
    font-size: 18px;
    font-weight: 500;
    color: $secondary-color;
  }
  
  .ticket-section h4 {
    font-size: 16px;
    font-weight: 500;
    color: $secondary-color;
    margin-bottom: 8px;
  }
  
  .original-request,
  .message {
    background: $secondary-color;
    padding: 16px;
    border-radius: 6px;
    position: relative;
  }
  
  .timestamp {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: 12px;
    color: $secondary-color;
  }
  
  .messages-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .message-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
  
  /* Form Styles */
  .support-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: $secondary-color;
  }
  
  .form-select {
    padding: 8px 12px;
    border: 1px solid $secondary-color;
    border-radius: 6px;
    font-size: 14px;
    color: $text-white;
    background-color: $primary-color;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23666666'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    padding-right: 40px;
  }
  
  .form-group .form-textarea {
    padding: 12px;
    border: 1px solid $primary-color !important;
    border-radius: 6px;
    font-size: 14px;
    color: $primary-color !important;
    resize: vertical;
    outline: none;
    min-height: 100px;
  }
  
  .form-textarea::placeholder {
    color: $text-white;
  }
  
  .submit-button {
    background: $primary-color;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    align-self: flex-start;
  }
  
  .submit-button:hover {
    background: $secondary-color;
    color: $primary-color;
  }
  
  .empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $primary-color;
    font-size: 16px;
  }
  
  @media (max-width: 768px) {

    .support-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      width: 100%;
      height: 100vh;
      margin: 0;
      border-radius: 0;
    }
  
    .sidebar {
      width: 100%;
      max-height: 40vh;
    }
  
    .main-content {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  
    .form-row {
      grid-template-columns: 1fr;
    }
  
    .submit-button {
      width: 100%;
    }
  
    .ticket-meta {
      flex-direction: column;
      gap: 4px;
    }
  
    .support-container {
      flex-direction: column;
    }
  }