@import "../variables";

.contactUs-page-style {
    height: 85vh;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-data {
    span {
        font-size: map_get($fontSize, l-small);
        letter-spacing: 10px;
        color: $secondary-color;
        font-weight: 500;
    }
    h3 {
        font-size: map_get($fontSize, l-med);
        font-weight: bold;
        color: $primary-color;
    }
    p {
        font-size: map_get($fontSize, x-med);
        color: $secondary-color;
    }
}

.btn-form-submit {
    background-color: $primary-color;
    color: $text-white;
    width: 150px;
    height: 48px;
    border-radius: 16px;
    border: 2px solid $primary-color !important;
    font-size: map_get($fontSize, l-small);
}

.btn-form-submit:hover {
    border: 2px solid $primary-color;
    color: $primary-color;
}

.footer-p {
    color: $primary-color !important;
    font-size: map_get($fontSize, small);
}

.form-design input, textarea {
    border: 2px solid $primary-color !important;
    color: $primary-color !important;
}

.form-design input::placeholder, .form-design textarea::placeholder {
    color: $primary-color !important;
    opacity: 0.3;
}



.form-control {
    background-color: transparent;
    color: $secondary-color;
    border: 2px solid $primary-color;
    transition: background-color 0.3s ease, border 0.3s ease;

    &:focus {
        background-color: $primary-background;
        outline: none;
        border-color: $accent-color;
    }

    &::placeholder {
        color: lighten($secondary-color, 20%);
    }
}

textarea.form-control {
    resize: vertical;
}

@media (max-width: 576px) {
    .contactUs-page-style {
        height: auto;
        padding: 20px;
    }

    .contact-data {
        h3 {
            text-align: left;
            font-size: map_get($fontSize, x-med);
        }
        p {
            font-size: map_get($fontSize, x-med);
            text-align: left;
        }
    }

    .btn-form-submit {
        width: 150px;
        height: 48px;
        font-size: map_get($fontSize, l-small);
    }

    .form-design {
        display: flex;
        flex-direction: column;
    }

    .footer.container {
        width: 70%;
        height: 350px;
    }
}

@media (min-width: 576px) {
    .contactUs-page-style {
        height: auto;
        padding: 20px;
    }

    .contact-data {
        h3 {
            text-align: left;
            font-size: map_get($fontSize, x-med);
        }
        p {
            font-size: map_get($fontSize, x-med);
            text-align: left;
        }
    }

    .btn-form-submit {
        width: 200px;
        height: 50px;
        font-size: map_get($fontSize, l-small);
    }

    .form-design {
        display: flex;
        flex-direction: column;
    }

    .footer.container {
        width: 70%;
        height: 350px;
    }
}

@media (min-width: 768px) {
    .contactUs-page-style {
        height: auto;
        padding: 40px;
    }

    .contact-data {
        h3 {
            text-align: left;
            font-size: map_get($fontSize, x-med);
        }
        p {
            font-size: map_get($fontSize, x-med);
            text-align: left;
        }
    }

    .btn-form-submit {
        width: 250px;
        height: 52px;
        font-size: map_get($fontSize, x-med);
    }

    .form-design {
        display: flex;
        flex-direction: column;
    }

    .footer.container {
        width: 100%;
        height: 170px;
    }
}

@media (min-width: 992px) {
    .contactUs-page-style {
        height: auto;
        padding: 40px;
    }

    .contact-data {
        h3 {
            text-align: center;
            font-size: map_get($fontSize, l-med);
        }
        p {
            font-size: map_get($fontSize, l-med);
            text-align: center;
        }
    }

    .btn-form-submit {
        width: 300px;
        height: 54px;
        font-size: map_get($fontSize, med);
    }

    .form-design {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer.container {
        width: 90%;
        height: 150px;
    }
}

@media (min-width: 1200px) {
    .contactUs-page-style {
        height: auto;
        padding: 40px;
    }

    .contact-data {
        h3 {
            text-align: center;
            font-size: map_get($fontSize, l-med);
        }
        p {
            font-size: map_get($fontSize, l-med);
            text-align: center;
        }
    }

    .btn-form-submit {
        width: 350px;
        height: 56px;
        font-size: map_get($fontSize, med);
    }

    .form-design {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer.container {
        width: 90%;
        height: 150px;
    }
}