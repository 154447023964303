@import "../variables";

// .onBoardingMainStyle styles



.onBoardingMainStyle {
  background-color: $secondary-color !important;
  width: 100%;
  height: 100% !important;
  // overflow: hidden !important;
  border-radius: 32px 32px 0 0;
.container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
  .row {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

}

.navbar .container-fluid a {
  color: $primary-color !important;
}


// Text styles
.q1-onboarding h1 {
  padding-top: 1rem;
  font-size: 32px;
  color: #fff;
  font-weight: bold;
}

.q1-onboarding p {
  
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}
.q1-onboarding .step5-header {
  font-size: 32px;
}

.q1-onboarding .step5-header-s2 {
  font-size: 18px;
}
// Button styles
.btn-onBorading-Answers {
 
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: $primary-color !important;
  }
  background-color: #fff !important;
  width: 100% !important;
  // height: 85px !important;
  border: 3px solid #fff !important;
  color: $primary-color !important;
  font-size: 14px !important;
  font-weight: 300 !important;

  h7 {
    text-align: center !important;
    font-size: 15px !important;
    
  }
}

.btn-onBorading-Answers:hover {
  border: 3px solid $primary-color !important;
color: $text-white !important;
  p {
    color: $primary-color !important;
  }
}

.btn-onBorading-Answers span {
  color: $primary-color !important;
  font-size: 32px !important;
  font-weight: bold !important;
}

.btn-next,
.btn-previous,
.btn-done {
  width: 100%;
  height: 48px;
  border-radius: 8px !important;
  font-weight: bold;
  margin: 0 8px; // Adjust margin as needed
}

// Specific button styles
.btn-next {
  background-color: $primary-color;
  color: $text-white !important;
  border: 3px solid $primary-color !important;
  transition: 0.3s;
}

.btn-next:hover {
  border: 2.5px solid $primary-color !important;
  color: $primary-color !important;
  background-color: none !important;
}

.btn-previous {
  color: $primary-color !important;
  border: 3px solid $primary-color !important;
  transition: 0.3s;
  border: 2.5px solid #fff !important;
}

.btn-previous:hover {
  border: 2.5px solid #fff !important;
  color: $primary-color !important;
  background-color: $text-white;
}

.btn-done {
  background-color: $accent-color !important;
  color: $text-white !important;
  border: 3px solid $accent-color !important;
  transition: 0.3s;
}

.btn-done:hover {
  color: $primary-color !important;
}

// Selected state for answer buttons
.btn-onBorading-Answers.selected {
  border: 3px solid $accent-color !important;
  color: #fff !important;
  background-color: $accent-color !important;

  p {
    color: #fff !important;
  }
}

// Scrollable container styles
.scrollable-buttons-container {
  height: 55vh;
  overflow-x: hidden;
  // padding-right: 10px;
  -ms-overflow-style: none;
}

.scrollable-buttons-container::-webkit-scrollbar {
  width: 0;
}

// Custom bullets
.custom-bullets {
  font-weight: 200;
  list-style-type: disc !important;
  margin-left: 20px;
}

// Suggest button styles
.btn-suggest {
  width: 100%; // Full width to match alignment
  height: 48px; // Consistent height with other buttons
  margin: 16px 0; // Adjust spacing above and below the button
  border: 2px solid $primary-color !important;
  color: $primary-color;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px; // Match the button radius
  padding: 30px !important;
}

.btn-suggest:hover {
  background-color: $primary-color !important;
  color: #fff;
}

// Input and select styles
.ant-input {
  border-radius: 8px !important;
  height: 40px !important;
}

.country-dropdown-container {
  position: relative;
  width: 100%;
}
.dropdown-container {
  position: relative;
  width: 100%;
}
.country-dropdown-container .selection-step {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  background: rgba($primary-color, 0.1);
  border: 2px solid transparent;
  outline: none;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  box-shadow: 0 4px 12px rgba($secondary-color, 0.15);
  color: $text-white;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-overflow-style: none !important;
  overflow-y: auto;
}
.selection {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  background: rgba($primary-color, 0.1);
  border: 2px solid transparent;
  outline: none;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  box-shadow: 0 4px 12px rgba($secondary-color, 0.15);
  color: $text-white;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-overflow-style: none !important;
  overflow-y: auto;
}

.selection:hover {
  background-color: rgba($primary-color, 1);
  cursor: pointer;
  border-color: $secondary-color;
  color: $text-white;
}

.selection.focused {
  background-color: rgba($primary-color, 0.5);
  border-color: $secondary-color;
  box-shadow: 0 0 0 3px rgba($secondary-color, 0.3);
}

.selection option {
  background-color: $primary-color;
  color: $text-white;
  cursor: pointer;
  font-weight: 500;
  -ms-overflow-style: none !important;
  overflow-y: auto;
}

.selection::-webkit-scrollbar {
  width: 6px;
  overflow-y: hidden;
}

.selection::-webkit-scrollbar-thumb {
  background-color: $secondary-color !important;
  border-radius: 4px;
  overflow-y: hidden;
}

/* For Firefox */
.selection {
  scrollbar-width: thin;
  scrollbar-color: $secondary-color transparent;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  color: $primary-color;
}

.dropdown-icon.focused {
  transform: translateY(-50%) rotate(180deg);
}

@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(1);
  }
  50% {
    transform: translateY(-50%) scale(1.6);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}

.selection:hover + .dropdown-icon {
  animation: pulse 1s infinite;
}




// Button text styles
.btn-onBorading-Answers h5 {
  color: $primary-color !important;
}

.btn-onBorading-Answers h4 {
  font-size: 14px;
  color: $primary-color !important;
}

// Button container
.button-container {
  display: flex;
  justify-content: space-between; // Align buttons with space between them
  align-items: center; // Center align vertically
  // margin-top: 60px; // Increased margin to move buttons down
  // padding-top: 16px; // Optional: Add padding for additional spacing
  position: sticky;
  bottom: 0.2%;
  left: 50%;
  // transform: translateX(-50%);
  z-index: 20;
  width: 100%;
  background-color: $secondary-color ;
}
.name-suggestions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-item {
  flex: 1 1 45%; /* Adjust the basis percentage to control the width */
  max-width: 50%; /* Ensure items don't exceed the specified width */
  box-sizing: border-box;
  padding: 0 10px; /* Adjust padding as needed */
}

#root > div > div > div > div > div.col-7 > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div > div > div > div > div.col-7 > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div > div > div > div > div.col-7 > div.ant-steps > div > div > div.ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) { 

  .onBoardingMainStyle {
    height: 100% !important;
  }

  #root > div > div > div > div > div.col-7 > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;

  }

  #root > div > div > div > div > div.col-7 > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 .ant-steps-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
 
}

@media (max-width: 987px) {  
  .onBoardingMainStyle {
    height: 100% !important;
  .container {

    
    width: 100%;
    .row {

      width: 100%;

      .col-5 {
        display: none;
      }
    }
  }
  
  }
}

@media (max-width: 480px) {  

  .onBoardingMainStyle {
    height: 100% !important;
  }
}
.onboardingcard {

  background-color: $secondary-color;
  height: 490px;
  // overflow-y: scroll;
  // overflow-y: scroll;
.steps {
  position: relative;
  overflow-y: auto;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    width: 6px; /* Visible and narrow scrollbar */
   position: relative;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color; /* Use your theme's color */
    border-radius: 4px;

  }
}
-ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    width: 6px; /* Visible and narrow scrollbar */
   position: relative;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color; /* Use your theme's color */
    border-radius: 4px;

  }
}

// @media (max-width: 1559px) {  

//   .onBoardingMainStyle {
//    background-color: black;
//   }
// }

@media (max-width: 1559px) {  

  .layout-contenttt {
    height: auto !important;

  }
  .onBoardingMainStyle {
    height: 100vh !important;
  }
}

@media (max-width: 2000px) {  
  .layout-contenttt {
    height: auto !important;

  }
  .onBoardingMainStyle {
    height: 86.8vh !important;
  }
}

@media (max-width: 488px) {  
  #root > div > div > div > div > div > div > div.col-7.onboardingcard > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #root > div > div > div > div > div > div > div.col-7.onboardingcard > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 > div{
flex: 1;
  }
  .layout-contenttt {
    height: 100vh !important;

  }
  .onBoardingMainStyle {
    height: 100vh !important;
  }
  .onBoardingMainStyle .container  {
    // align-items: start !important;
  }
}

@media (max-width: 825px) {  

  .onBoardingMainStyle {
    height: 100vh !important;
  }
}


@media (max-width: 393px) {  

  .onBoardingMainStyle {
    height: 110% !important;
  }
}



@media (max-width: 572px) { 
  .layout-contenttt {
    height: 100vh !important;

  }
  .onBoardingMainStyle {
    height: 100% !important;
  }
  .onBoardingMainStyle .container .row .col-7 {
    width: 100%;
    }
  

  .onBoardingMainStyle .container .row {
  width: 100%;
  }

  #root > div > div > div > div > div.col-7 > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;

  }

  #root > div > div > div > div > div.col-7 > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0 .ant-steps-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

 }

 .selection-step5{
  height: 100%;
 }