@import "../variables";

// ==================================================
// SecondeSection Styles
// ==================================================
.features-section-new {
  padding: 2rem 0;
  background-color: #F8F8FF;

  // Card container styles – ensure they stretch evenly
  .box-ai,
  .box-3rd {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 9px 40px rgba(255, 255, 255, 0.225);
    display: flex;
    flex-direction: column;
    height: 100%; // each card fills its column

    // Headings inside the cards
    h5 {
      margin: 0;
      margin-bottom: 1rem;
    }
  }

  .box-ai-indev{
    background-color: rgba($secondary-color, 0.1);
    padding: 12px;
    border-radius: 12px;
  }

  // Image styles for card images
  .card-img {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  // ------------------------------
  // Net Profit Section
  // ------------------------------
  .net-profit-title {
    margin-bottom: 1rem;
  }

  .net-profit-stats {
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }
    .stat-label-design {
      font-size: 0.85rem;
      color: #555;
      margin-bottom: 0.25rem;
      white-space: nowrap;
    }
    .stat-value {
      font-size: 1rem;
      font-weight: 600;
      white-space: nowrap;
    }
    .arrow-down,
    .arrow-up {
      display: inline-flex;
      align-items: center;
      margin-right: 0.25rem;
    }
  }

  // Divider line styling
  .divider-line {
    margin: 0.5rem 0 1rem;
    border: 0;
    border-top: 1px solid #e0e0e0;
  }

  // Progress bar color
  .progress-bar {
    background-color: $primary-color;
  }

  // Revenue segments list text color
  .li-fin {
    color: $secondary-color;
  }

  // ------------------------------
  // Custom Layout for the Pie Card
  // ------------------------------
  .row-layout {
    flex-direction: row;
    gap: 1rem; // adds space between the image and text columns
  }

  // ------------------------------
  // Balance Sheet Table Styling
  // ------------------------------
  .balance-sheet-container {
    .table-balance {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      overflow: hidden;

      thead {
        background-color: #A3C0D8;
        color: #FFFFFF;
        font-weight: 600;
        th {
          padding: 1rem;
          text-align: left;
          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
          &.table-title {
            font-size: 1.1rem;
          }
        }
      }

      tbody {
        background-color: #F8FBFF;
        color: #2F2F2F;
        tr {
          border-top: 1px solid #E0E0E0;
          &:last-child {
            td:first-child {
              border-bottom-left-radius: 10px;
            }
            td:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
        td {
          padding: 1rem;
        }
      }
    }
  }
}

// ==================================================
// Responsive Styles
// ==================================================
@media (max-width: 768px) {
  .features-section-new {
    padding: 1rem 0;

    .box-3rd {
      padding: 1rem;
    }
  }
}

@media (max-width: 576px) {
  .features-section-new {
    .box-ai,
    .box-3rd {
      padding: 1rem;
    }
    h5 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
}

// ==================================================
// Existing Home Styles (if used elsewhere)
// ==================================================
.home {
  position: relative;
  background: radial-gradient(
    circle,
    rgba(152, 193, 217, 1) 0%,
    rgba(61, 90, 128, 1) 100%
  );
  margin-top: -16px;
  overflow: hidden;
}

.home-content {
  h1 {
    text-align: center;
    font-size: 100px;
    line-height: 1.1;
    font-weight: bold;
    color: $text-white;
  }

  .btn-primary-ai {
    background-color: $primary-color;
    color: $text-white;
    border-radius: 12px;
    border: 1px solid $primary-color;
    font-weight: bold;

    &:hover {
      background-color: transparent;
      color: $primary-color;
    }
  }
}

@media (max-width: 768px) {
  .home-content {
    h1 {
      font-size: 100px;
    }
    p {
      font-size: map_get($fontSize, l-small);
    }
  }
}

@media (max-width: 576px) {
  .home {
    height: auto;
  }
  .home-content {
    h1 {
      font-size: 40px;
    }
  }
}

@media (min-width: 768px) {
  .home-content {
    h1 {
      font-size: 100px;
    }
  }
}

.img-design-info-two{
    background-color: $secondary-color;
    padding: 15px;
    border-radius: 12px;
}

.text-info-two{
    h5{
        font-size: 16px;
        font-weight: bold;
        color: $primary-color;
    }
    p{
        font-size: 16px;
        color: $secondary-color;
    }
}

.text-info-chart{
    h5{
        font-size: 16px;
        font-weight: bold;
        color: rgba($secondary-color, $alpha: 0.5);
    }
    p{
        font-size: 18px;
        font-weight: bold;

        color: $primary-color;
    }
}

// Custom container for SWOT items with a gap between items
.swot-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; // Adjust this value to increase or decrease space between items
  }
  
  // Each SWOT card takes up slightly less than 50% width to allow for the gap
  .swot-col {
    flex: 0 0 calc(50% - 0.5rem); // two items per row with a 1rem gap between them
  }
  
  .text-primary-profit{
    color: $secondary-color;
  }

  .text-danger-down{
    color: #D62828;
  }

  .text-success-up{
    color: #52B788;
  }