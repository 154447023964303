@import "../variables";

.home {
  position: relative;
  background: rgb(152, 193, 217);
  background: radial-gradient(circle, rgba(152, 193, 217, 1) 0%, rgba(61, 90, 128, 1) 100%);
  margin-top: -16px;
  overflow: hidden;
}

.home-content {
  h1 {
    text-align: center;
    font-size: 100px;
    line-height: 1.1;
    font-weight: bold;
    color: $text-white;
  }

  .btn-primary-ai {
    background-color: $primary-color;
    color: $text-white;
    border-radius: 12px;
    border: 1px solid $primary-color;
    font-weight: bold;
  }

  .btn-primary-ai:hover {
    background-color: transparent;
    color: $primary-color;
  }
}





@media (max-width: 768px) {
  .home-content h1 {
    font-size: 100px;
  }

  .home-content p {
    font-size: map_get($fontSize, l-small);
  }


}

@media (max-width: 576px) {
  .home {
    height: auto;
  }

  .home-content h1 {
    font-size: 40px;
  }

}

@media (min-width: 768px) {
  .home-content h1 {
    font-size: 100px;
  }
}

// Blur the background behind the modal
.ant-modal-mask {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

// Optional: modern modal styling (if needed)
.modern-modal {
  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
}

// Your provided button styles
.btn-login-header-modal {
  background-color: $secondary-color;
  width: 100%;
  height: 50px;
  font-weight: bold;
  border-radius: 32px !important;
  color: $text-white !important;
  margin-right: 15px;
  transition: 0.4s;
  font-size: 22px !important;
}

.btn-login-header-modal:hover {
  background-color: $primary-color !important;
  border: $primary-color;
  color: $primary-background !important;
}

.btn-tryNow-modal {
  width: 100%;
  background-color: $primary-color !important;
  height: 50px;
  font-weight: bold;

  border-radius: 32px !important;
  color: $primary-background !important;
  border: 2px solid $primary-color !important;
  margin-right: 15px;
  transition: 0.4s;
  font-size: 22px !important;
}

.btn-tryNow-modal:hover {
  background-color: $secondary-color !important;
  border: 2px solid $secondary-color !important;
  color: $text-white !important;
}

