@import "../variables";


#root > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.register.style-steps > div:nth-child(2) > div > div.flag-dropdown.open > ul {
    display: flex !important;
    flex-direction: column !important;
    width: 400px !important;
}
#root > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.register.style-steps > div:nth-child(2) > div > div.flag-dropdown.open > ul > li {
    display: flex !important;
    align-items: center;
}

#root > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.register.style-steps > div:nth-child(2) > div > div.flag-dropdown.open > ul > li.search > input {
    width: 100%;
}


input::placeholder,
textarea::placeholder {
  color: $primary-color !important; /* Replace with your desired color code */
  opacity: 0.5 !important; /* This controls the transparency of the placeholder */
}

.FormRegister {
    width: 100% !important;
    // height: fit-content !important;
}
.register-section {
   
    overflow: auto;
    height: calc(100vh - 127px) !important;
    background: linear-gradient(-270deg, rgba(152,193,217,1) 0%, rgba(224,251,252,1) 100%);
    position: relative;
    top: 111px;
    padding: 20px;
    border-radius: 32px 32px 0 0;
    width: 100%;
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: transparent transparent; /* Firefox */
}

// Chrome, Safari, and Edge
.register-section::-webkit-scrollbar {
    width: 8px;
}

.register-section::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.register-section::-webkit-scrollbar-track {
    background-color: transparent;
}

#root > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.ant-steps.ant-steps-vertical.css-dev-only-do-not-override-ni1kz0{
    display: flex;
    justify-content: center;
    align-items: center;
}
#root > div > div > div > div > div.col-7.form-desin-login.register-design.d-flex.justify-content-center.align-items-center > form > div.ant-steps > div.ant-steps-item > div {
    display: flex;
}
#root > div > div > div > div > div > form > div.ant-steps > div.ant-steps-item > div > div.ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.register-design{
    height: 82vh;
    h2{
        font-size:  map_get($fontSize, med);
        font-weight: bold;
    }
}

.register-logo{
    position: sticky;
    top: 0;
    width: 100%;
}

.form-control{
    background-color: transparent !important;
    border: 2px solid $text-white;
    border-radius: 8px;
}

.form-label{
    font-size: map_get($fontSize, l-small);
    color: $text-white;
}

.line1{
    position: relative;
    bottom: 7px;
    width: 100%;
    background-color: $primary-color;
    height: 1.5px;
}

span{
    font-size:  map_get($fontSize, l-small);
    color: $text-white;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .span-route{
            color: $primary-color;
            font-weight: 600;
            font-size:  map_get($fontSize, l-small);
    }
}

.style-steps{
   
    width: 100%;
    height: 50vh;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.6rem;
}

.otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
  
.otp-input {
    width: 40px !important;
    height: 40px;
    margin: 0 10px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.resend-otp-link {
    display: inline-block;
    margin-top: 10px;
    text-decoration: none;
    color: $primary-color;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

.btn-next-register{
        width: 100%;
        height: 48px;
        border-radius: 8px !important;
        background-color: $primary-color !important;
        color:  $text-white !important;
        font-weight: bold;
        border: 3px solid $primary-color !important;
        transition: 0.3s;

}

.btn-next-register:hover{
    background-color: $primary-color !important;
    border: 3px solid $primary-color !important;
    color: $text-white !important;

}

.btn-previous-register{
    width: 100%;
    height: 48px;
    border-radius: 8px !important;
    background-color:$secondary-color !important;
    color: $primary-color !important;
    font-weight: bold;
    border: 3px solid $secondary-color !important;
    transition: 0.3s;
}

.btn-previous-register:hover{
background-color: transparent !important;
border: 3px solid $secondary-color !important;
color:  $secondary-color !important;
}

.btn-done-register{
    width: 100%;
    height: 48px;
    border-radius: 8px !important;
    background-color:$primary-color !important;
    color: $text-white !important;
    font-weight: 900;
    border: 3px solid $primary-color !important;
    transition: 0.3s;
}

.btn-done-register:hover{
    background-color: $text-white !important;
    border: 3px solid $text-white !important;
    color:   $primary-color  !important;
}


@media (max-width: 765px) {
    span {
        font-size:  map_get($fontSize, l-small);
    }
   
    .span-route{
        color: $primary-color;
        font-weight: 600;
        font-size:  map_get($fontSize, l-small);
}
}

@media (max-width: 576px) {
    .register-logo{
        display: none;
    }
    .register-design{
        width: 100%;
        height: auto;
    }
    .register-section{
        width: 100% !important;
         height: 100%;
    
    .style-steps{
        width: 100% !important;
        height: 100%;
        padding: 16px;
    }
        .col-6, .col-2, .col-4, .col-8 {
            width: 100% !important; /* Full-width inputs */
            margin-bottom: 10px;    /* Space between inputs */
            padding: 3px 6px;
        }
    
        /* Make form controls full width */
        .form-control {
            width: 100%; /* Full width for form controls */
        }
    
        /* Optional: Adjust padding and margins for rows */
        .row {
            margin-left: 0; /* Remove side margins for smaller screens */
            margin-right: 0;
    }
    .btn-done-register{
        font-size: 12px; 
    }
    
}
}

@media (min-width: 576px) {
    .register-logo{
        display: none;
    }
    .register-design{
        width: 100%;
        height: auto;
    }
    .register-section{
        width: 100% !important;
        height: auto;
    
    .style-steps{
        width: 100% !important;
        height: auto;
        padding: 16px;
    }
        .col-6, .col-2, .col-4, .col-12 {
            width: 100% !important; /* Full-width inputs */
            margin-bottom: 10px;    /* Space between inputs */
            padding: 3px 6px;
        }
    
        /* Make form controls full width */
        .form-control {
            width: 100%; /* Full width for form controls */
        }
    
        /* Optional: Adjust padding and margins for rows */
        .row {
            margin-left: 0; /* Remove side margins for smaller screens */
            margin-right: 0;
    }
    .btn.btn-done-register{
        font-size: 14px; 
    }
    
}
}

@media (min-width: 768px){
    .register-logo{
        display: none;
    }
    .register-design{
        width: 100%;
        height: auto;
    }
    .register-section{
        width: 100% !important;
        height: auto;
    
    .style-steps{
        width: 100% !important;
        height: auto;
        padding: 16px;
    }
        .col-6, .col-2, .col-4, .col-12 {
            width: 100% !important; /* Full-width inputs */
            margin-bottom: 10px;    /* Space between inputs */
            padding: 3px 6px;
        }
    
        /* Make form controls full width */
        .form-control {
            width: 100%; /* Full width for form controls */
        }
    
        /* Optional: Adjust padding and margins for rows */
        .row {
            margin-left: 0; /* Remove side margins for smaller screens */
            margin-right: 0;
    }
    
}
.btn.btn-done-register{
    font-size: 16px; 
}
}

@media (min-width: 992px) {
    .register-logo{
        display: block;
        width: 100%;
        height: auto;
    }
    .register-section{
        width: 100%;
    }
    .register-design{
        width: 50%;
    }
    .btn.btn-done-register{
        font-size: 16px; 
    }
}

.alert-message{
    width: 100%;
    background-color: #3d5a802a;
    border-radius: 12px;
    color: $primary-color;
    font-size: 16px;
    padding: 8px;

}


.form-control{
    width: 100% !important;
    border: 2px solid $primary-color !important;
    border-radius: 8px !important;
}


.react-tel-input .flag-dropdown{
    background-color: $text-white !important;
    border: 2px solid $primary-color !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: $text-white !important;
}

.react-tel-input .selected-flag .arrow{
    border-top: 4px solid $primary-color!important;
}

// .anticon svg {
//     color: $primary-color;
// }

