@import "../variables";

.plansSection {
    position: relative;
    top: 100px;
    background: linear-gradient(-270deg, rgba(152, 193, 217, 1) 0%, rgba(224, 251, 252, 1) 100%);
    padding: 70px 20px;
    border-radius: 32px 32px 0 0;
    text-align: center;

    h2 {
        font-size: 36px;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: 20px;
    }

    .row {
        display: flex;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap; /* Ensures responsiveness */
    }
}

.plan-card {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1 1 calc(33.33% - 30px); /* Adjusts card size for responsiveness */
    max-width: 350px;
    min-width: 280px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
    }

    .plan-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        color: $primary-color;
        letter-spacing: 1.5px;
    }

    .plan-price {
        font-size: 32px;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: 20px;

        span {
            font-size: 16px;
            color: #6c757d;
        }
    }

    .btn-plan-select {
        background-color: $primary-color;
        color: #ffffff;
        border-radius: 25px;
        font-size: 16px;
        padding: 10px 30px;
        margin-bottom: 20px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $primary-color;
        }
    }

    .features-container {
        text-align: left;

        .feature-row {
            display: flex;
            align-items: center;

            .correct-logo {
                width: 24px;
                height: 24px;
                flex-shrink: 0;
            }

            .feature-text {
                font-size: 16px;
                color: #6c757d;
                margin-left: 15px;
            }
        }
    }
}

.plan-card-popular {
    border: 2px solid #ee6d4d;
    position: relative;

    .badge-popular {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #ee6d4d;
        color: #ffffff;
        padding: 5px 15px;
        border-radius: 15px;
        font-size: 12px;
        letter-spacing: 1px;
    }
}

/* Media query for responsiveness */
@media (max-width: 768px) {
    .plan-card {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .row {
        gap: 20px;
    }
}
