@import "../variables";

/* Container for the whole reports page/cards */
.reports-page-container {
  display: flex;
  flex-direction: column;
  gap: 30px; // Space between cards
  padding: 20px;
}

.header-v2-report {
  display: flex;              // Makes the container a flex container
  justify-content: center;    // Centers items horizontally
  align-items: center;        // Centers items vertically
  border-radius: 22px;
  border: 2px solid $primary-color !important;
  width: 253px;
  height: 61px;
  margin: 0 auto;             // Centers the header on the page

  span {
    font-size: 22px;
    color: $primary-color;
    font-weight: bold;
  }
}

/* Card Report Component styles */
.card-report-v2 {
  margin-top: 20px;
  width: 100%;
  min-height: 300px; // Increased card height for more space
  background-color: #f5f7f9;
  border-radius: 12px;
  text-align: start;
  padding: 30px; // Extra padding for spacing

  @media (max-width: 768px) {
    min-height: auto; // Allow height to adjust on smaller screens
  }

  .header-card-report-v2 {
    h6 {
      font-size: 14px;
      font-weight: 900;
      color: rgba($secondary-color, 0.7);
    }
    h5 {
      font-size: 22px;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .inner-card-v2 {
    width: 100%;
    height: 200px;
    background-color: $text-white;
    border-radius: 8px;
    padding: 15px; // Inner spacing

    @media (max-width: 768px) {
      height: auto;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      color: $secondary-color;
    }
    p {
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
    }

    .profit-lottie-v2 {
      width: 150px; // Lottie animation size
      height: 150px;

      @media (max-width: 768px) {
        width: 120px;
        height: 120px;
      }
    }
  }
}

/* Button used on the card */
.btn-view-report {
  background-color: $text-white;
  width: 222px;
  height: 41px;
  font-size: 22px;
  font-weight: bold;
  color: $secondary-color;
  display: flex;              // Use flex to center content
  align-items: center;        // Vertical centering
  justify-content: center;    // Horizontal centering
  margin: 0 auto;             // Auto margins on left and right center it
  border: none;               // Remove default border if needed
}
