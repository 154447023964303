@import "../variables";

/* Dashboard container */
.dashboard-rows-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Fixed height for Account/Support Cards on larger screens */
.account-support-row {
  height: 200px;
}

/* On smaller screens, allow flexible height */
@media (max-width: 768px) {
  .account-support-row {
    height: auto !important;
  }
}

/* Responsive image adjustments */
.responsive-image {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .responsive-image {
    height: auto;
  }
}

/* Layout Content */
.layout-content {
  height: calc(100vh - 173px) !important; // Adjusted to account for the fixed header
  display: flex;
  flex-direction: column;
  scrollbar-width: none;        /* Firefox */
  -ms-overflow-style: none;     /* IE/Edge */
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

@media (max-width: 393px) {
  .layout-content {
    height: 110vh !important;
    overflow-y: auto !important;
    flex-direction: column;
  }
}
.Dash {
   flex-direction: column;
  align-items: center !important;
}
/* Main system container */
.system-main-container {
  display: flex !important;
  // flex-direction: column;
  // align-items: center !important;
  width: 100% !important;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color;
    border-radius: 4px;
  }
  
  @media (max-width: 930px) {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    overflow-y: auto;
    
    .div1,
    .div2,
    .div3 {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100%;
      gap: 0.5rem !important;
      height: auto;
      
      .card {
        width: 100% !important;
        border-radius: 1rem !important;
      }
    }
  }
}

/* Modal responsiveness */
:where(.css-dev-only-do-not-override-p8b6i).ant-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-modal .ant-modal-content {
  width: 80%;
  max-width: 600px;
  height: 70vh;
  margin: 0;
  position: relative;
}

@media (max-width: 1200px) {
  :where(.css-dev-only-do-not-override-p8b6i).ant-modal .ant-modal-content {
    width: 85%;
  }
}

@media (max-width: 800px) {
  :where(.css-dev-only-do-not-override-p8b6i).ant-modal .ant-modal-content {
    width: 95%;
    height: 80vh;
  }
}

.text-model h2 {
  color: $primary-color;
  font-weight: 600;
}

.text-model p {
  color: $primary-color;
}

.text-model span {
  color: $accent-color;
  font-size: 1.5rem;
}

.btn-primary-model {
  background-color: $accent-color;
  color: $text-white;
  padding: 8px 16px;
  border: none;
  border: 2px solid $accent-color !important;
  cursor: pointer;
}

.btn-primary-model:hover {
  border: 2px solid $accent-color !important;
  color: $accent-color !important;
  background-color: transparent !important;
}

.button-x {
  color: $primary-color !important;
}

.modal-style h4 {
  font-size: 18px;
  color: $primary-color;
  font-weight: bold;
}

.modal-style p {
  font-size: 16px;
  color: $primary-color;
}

.btn-welcome-model {
  background-color: $accent-color;
  border: 2px solid $accent-color;
  color: $text-white;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  padding: 8px 0;
  border-radius: 4px;
  cursor: pointer;
}

.btn-welcome-model:hover {
  border: 2px solid $accent-color !important;
  color: $accent-color !important;
  background-color: transparent !important;
}

.vh-modal {
  height: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
}

.responsive-image {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.responsive-image-1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.welcome-paragraph {
  padding: 0 15px;
  font-size: 14px;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  :where(.css-dev-only-do-not-override-p8b6i).ant-modal .ant-modal-content {
    width: 85%;
  }
}

@media (max-width: 800px) {
  :where(.css-dev-only-do-not-override-p8b6i).ant-modal .ant-modal-content {
    width: 95%;
    height: 80vh;
  }

  .hide-on-small {
    flex-direction: row !important;
    overflow-x: auto !important;
  }

  .modal-style {
    flex-direction: column;
  }

  .modal-style .col-12 {
    margin-bottom: 15px;
  }

  .image-container {
    height: 150px;
  }

  .text-model span {
    font-size: 1.2rem;
  }

  .btn-primary-model,
  .btn-welcome-model {
    font-size: 14px;
    padding: 6px 12px;
  }

  .userDev,
  .LocationDev,
  .BussinesIdea,
  .BussinesName,
  .SelectedOption,
  .AiSuggestion {
    padding: 12px;
    font-size: 16px;
  }

  strong {
    font-size: 18px;
  }

  .userValue {
    font-size: 16px;
  }

  .div1,
  .div2,
  .div3 {
    flex-direction: column;
  }

  .selectbusiness,
  .businesssettings,
  .useraccess,
  .selectscenario,
  .scenarioeditor,
  .planviewer,
  .AccountSettings,
  .FrequentQuestions,
  .RequestSupport,
  .PartnerProgram {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 1rem !important;
  }

  .selectbusinesstitle,
  .createdatlocation,
  .buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .selectbusinesstitle h3,
  .createdatlocation p,
  .buttons button {
    width: 100%;
    text-align: left;
  }

  .selectbusinesstitle i {
    margin-top: 10px;
  }

  .createdatlocation {
    justify-content: flex-start;
  }

  .createdatlocation p {
    margin-right: 0;
  }

  .buttons {
    flex-direction: column;
  }

  .buttons button {
    margin-bottom: 10px;
  }
}

.userDev,
.LocationDev,
.BussinesIdea,
.BussinesName,
.SelectedOption,
.AiSuggestion {
  background-color: $secondary-color;
  color: white;
  border-radius: 18px;
  text-align: center;
  padding: 15px;
  min-height: 20vh;
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row.g-4 {
  gap: 1rem;
}

strong {
  font-size: 20px;
  color: $primary-color;
}

.userValue {
  font-size: 18px;
  color: white;
  margin-top: 8px;
}

.textcolor {
  color: #3D5A80;
}

.custom-button1 {
  background: rgba(152, 193, 217, 0.3);
  border-radius: 0px 0px 0px 32px;
  border: none;
  color: #3D5A80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button2 {
  background: rgba(152, 193, 217, 0.3);
  border-radius: 0px 0px 32px 0px;
  border: none;
  color: #3D5A80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button3 {
  background: rgba(152, 193, 217, 0.3);
  border-radius: 0px 0px 0px 0px;
  border: none;
  color: #3D5A80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button4 {
  background: rgba(152, 193, 217, 0.3);
  border-radius: 0px 0px 32px 32px;
  border: none;
  color: #3D5A80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalbutton {
  color: #3D5A80;
  background-color: white;
}

.btn:hover {
  background-color: darken(rgba(152, 193, 217, 0.3), 10%);
  color: $text-white;
}

.custom-modal {
  background-color: white;
  padding: 0;
  border-radius: 8px;
}

.custom-modal .ant-modal-content {
  width: auto;
  padding: 0;
  background-color: rgba(61, 90, 128, 0.07);
}

.custom-modal-footer {
  text-align: center;
  background-color: #3D5A80;
  padding: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.ai-onboarding-loading-container{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height:1px !important;
}

.onboarding-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.ant-spin-dot {
  font-size: 48px;
  color: #2c3e50;
}

.ant-spin-dot-item {
  background-color: #2c3e50; /* Custom spinner color */
}
.toggle-container {
  display: flex;
  background-color: #FFE8E8;
  border-radius: 8px;
  padding: 4px;
  width: fit-content;
  flex-wrap: wrap;
}

.toggle-button {
  border: none;
  background: none;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease;
}

.toggle-button:hover {
  opacity: 0.9;
}

.toggle-button.active {
  background-color: #F37761 !important;
  color: white;
}
@media (max-width: 768px) {
  .toggle-button {
    padding: 10px 14px;
    font-size: 14px; /* Smaller text size for smaller screens */
  }
  .toggle-container {
   margin: auto;
   display: flex;
   justify-content: center;
   align-items: center;
  }
}
@media (max-width: 594px) {
  .toggle-button {
    padding: 10px 16px;
    font-size: 14px; /* Smaller text size for smaller screens */
  
  }

  .toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
@media (max-width: 480px) {
  .toggle-button {
    font-size: 12px; /* Even smaller text size for tiny screens */
    padding: 8px 12px;
    
  }
  .toggle-container {
    display: flex;
   justify-content: center;
   align-items: center;
  }
}

.cursor-blocked{
  cursor: not-allowed;
}